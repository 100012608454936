import md5 from 'md5'
import { ServerError, UserError } from "@/utils/errors"
// import jsSHA from 'jssha';

export default class carabi {
  constructor (address, project) {
    console.log('carabi constructor', address, project)
    this.address = address
    this.project = project
    this.token = null
    this.authorized = false
    this.debug = true
  }

  // получить токен (автосоздание гостевого)
  async getToken () {
    if (this.token === null) {
      if (this.project === null) {
        throw ({ 'SymbCode': 'NO_PROJECT', 'Message': 'Carabi project not set' })
      }
      console.log('getToken', this)
      let register = await this.serverHttp('authorize/register', {
        'project': this.project
      })
      this.token = register.token
    }
    return this.token
  }

  // Авториз
  async authorize (login, passw) {
    await this.getToken()
    console.log('carabiToken', this.token)
    login = login.trim()
    let passwHash = md5(login.toUpperCase() + passw).toUpperCase()
    let result = await this.serverHttp('authorize/auth', {
      'token': this.token,
      'login': login,
      'passhash': passwHash,
      'authtoken': 0
    })
    this.authorized = true
    return result
  }

  // Уничтожить токен
  unregister () {
    this.authorized = false
    if (this.token === null) return
    try {
      this.serverHttp('authorize/unregister', {
        'token': this.token
      })
    } catch (e) {}
    this.token = null
  }

  // Информация о токене
  async tokenInfo () {
    return this.serverHttp('admin/validateUserToken', {
      'token': this.token
    })
  }

  // Запрос
  async queryRaw (queryName, params = {}, count=1, offset=0, options={}) {
    await this.getToken();
    let runParams = {
      'token': this.token,
      'queryName': queryName,
      'count': count,
      'params': params
    };
    if (offset !== undefined) {
      runParams.start_count = offset
    }
    if (options.modId !== undefined) {
      runParams.mod_id = options.modId
    }
    try {
      let response = await this.serverHttp('query/run', runParams)
      return response.OutParams
    } catch (e) {
      e.query = queryName
      e.queryParams = params
      console.error('Query error',e)
      throw e
    }
  }

  async serverHttp (url, params) {
    // console.log('serverHttp', this.address, url, params)

    let response = await fetch(this.address + url, {
      method: 'POST',
      body: JSON.stringify(params)
    });
    let result = await response.json()
    // console.log('serverHttp result', result)
    if (typeof result.SymbCode !== 'string') {
      result.SymbCode = 'OK'
    }
    if (result.SymbCode !== 'OK') {
      let name = 'Appserver'
      let message = err.Message || 'Appserver Error'
      let showUser = false
      if (this.debug && err.SymbCode === 'QUERY_ERROR') {
          name = 'AppserverQuery'
          message = 'Query Error '+err.query+':\n'+escapeXml(JSON.stringify(err.queryParams))+'\n'+escapeXml(err.Message);
      }
      if (err.SymbCode === 'INVALID_TOKEN') {
          name = 'InvalidToken'
          message = "Invalid Token"
      }
      if (err.SymbCode === 'QUERY_USER_ERROR') {
          name = 'AppserverUser'
          message = err.Message
          showUser = true
      }
      throw new ServerError(message, name, null, {showUser})
    }
    return result
  }

  dateToIso(date) {
    if (date === null) return null;
    let re = new RegExp("^([0-9]{2})\.([0-9]{2})\.([0-9]{4})([\^\s]([0-9]{2}:[0-9]{2}:[0-9]{2}))?$");
    let d = date.match(re);
    if (!d) return null;
    let result = d[3]+'-'+d[2]+'-'+d[1];
    if ((d.length > 4) && (d[5])) {
      result += 'T'+d[5];
    } else {
      result += 'T00:00:00';
    }
    return result
  }

  /* sha256(str) {
      let shaObj = new jsSHA("SHA-256", "BYTES");
      shaObj.update(str);
      return shaObj.getHash("HEX");
  } */
}
