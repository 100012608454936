<template>
<!--isNoUser-->
<div v-if="!user" class="pd-middle-content">
  <div style="width: 200px">
    <Icon name="ContactNotRegistered" style="width: 100%; height: auto"/>
    <h3 class="margin-top">Пользователь не найден</h3>
  </div>
</div>

<!--isNotRegistered-->
<div v-else-if="!user.is_registered"
      class="pd-middle-content">
  <div style="width: 200px">
    <Icon name="ContactNotRegistered" style="width: 100%; height: auto"/>
    <h3 class="margin-top" v-text="'Контакт еще не зарегистрировался'"></h3>
  </div>
</div>

<!--isNotAccepted-->
<div v-else-if="!user.is_accepted"
      class="pd-middle-content">
  <div style="width: 200px">
    <Icon name="ContactNotAccepted" style="width: 100%; height: auto"/>
    <h3 class="margin-top" v-text="'Контакт еще не принял приглашение'"></h3>
  </div>
</div>

<!--is_accepted-->
<div v-else ref="contact" class="pd-profile">

  <div class="pd-profile-header two-row">
    <!--:class="gridPhotoClass">-->

    <!--Photo-->
    <div class="pd-profile-ava">
      <UserAva :size="compact ? 80 : 130" :contact="user"/>
    </div>

    <!--USER META-->
    <div class="pd-profile-ava-info">

      <!--LAST_NAME-->
      <div class="pd-profile-ava-info__title">
        <div v-text="user.last_name"></div>
        <div v-text="user.first_name"></div>
        <div v-text="user.middle_name"></div>
      </div>

      <!--NAME / MIDDLE_NAME-->
      <!--<div class="pd-profile-ava-info__sub-title">-->
      <!--  <span v-text="userProfile.user.first_name"></span>-->
      <!--  <span v-text="userProfile.user.middle_name"></span>-->
      <!--</div>-->

      <div class="pd-profile-ava-info__meta">
        <div class="flex column flex-1">
          <!--USER_TYPE-->
          <span v-if="user.info1" v-text="user.info1"></span>
          <!--clinic-->
          <span v-if="user.info2" v-text="user.info2"></span>
        </div>

        <span v-if="user.is_online"
              :class="{active : user.is_online}"
              class="pd-profile-ava-info__status"
              v-text="'В сети'"></span>
      </div>

      <!--                <div>-->
      <!--                  <a :href="`tel:+${userProfile.user.phone_number}`"-->
      <!--                     v-text="phoneFormat(userProfile.user.phone_number)"></a>-->
      <!--                </div>-->

    </div>

    <div class="pd-profile-header__actions" style="margin-top: 1rem">
      <!--                <button type="button"-->
      <!--                        @click="addChat"-->
      <!--                        class="pd-button small success"-->
      <!--                        v-text="$t('chat.new')">-->
      <!--                </button>-->
      <!--                <button type="button"-->
      <!--                        v-if="!isDoctor"-->
      <!--                        @click="addVideoChat"-->
      <!--                        class="pd-button small warning"-->
      <!--                        v-text="$t('conf.videoConf')">-->
      <!--                </button>-->

      <button v-for="item in actionsMenu" :key="item.TITLE"
              :class="item.CLASS"
              class="pd-button small"
              type="button"
              v-text="item.TITLE"
              @click.prevent="actionClick(item)">
      </button>

      <!--<button v-if="bookingData && bookingData.length"-->
      <!--        class="pd-button small"-->
      <!--        @click="showBookingDates = true"-->
      <!--        v-text="$t('chatAdd.addVideoChatTitle')">-->
      <!--</button>-->

      <!--                <a class="pd-button small info"-->
      <!--                   :href="`tel:+${userProfile.user.phone_number}`"-->
      <!--                   v-text="$t('profile.call')"></a>-->
    </div>

  </div>

  <NewVisit v-if="showNewVisit" @closed="showNewVisit=0" :doctor="this.user" :visitType="showNewVisit" />
</div>
</template>
<script>
import UserAva from '@/components/ui/elements/UserAva.vue'
import {backend, carabi} from '@/main'
import NewVisit from '@/modals/NewVisit'

export default {
  name: "ContactProfileHeader",
  components: {
    UserAva,
    NewVisit
  },
  props: {
    compact: Boolean,
    user: Object
  },
  data() {
    return {
      actionsMenu: [],
      showNewVisit: 0,
    }
  },
  computed: {
  },
  async created() {
    await this.getActionsMenu()
  },
  methods: {
    async getActionsMenu() {
        this.actionsMenu = await carabi.select( 'MD_GET_DOC_BP_BUTTON', { ID: this.user.profile_id }, 100 )
    },

    actionClick(item) {
        if (item.HREF) {
          location.href = item.HREF
          //backend.appApi.callPhone('+')
          return
        }
        console.log('actionClick',item)
        switch (item.ACTION) {
          case "addChat":
            this.newChat().then()
            break
          case "addVisit":
            this.showNewVisit = 1
            break
          case "addVideoChat":
            this.showNewVisit = 2
            break            
        }
    },

    async newChat() {
      let result = await backend.api.queries.query('MD_CREATE_ROOM_BY_PHONE_PATIENT', {
        PHONE: this.user.login
      })
      this.$router.push({name: 'ChatRoom', params: {roomId: result.RESULT}})      
    }
  }
}
</script>