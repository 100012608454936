export default class Event {
    constructor() {
        this.listeners = [];
    }

    // execute the callback everytime the label is trigger
    on(callback) {
        if (typeof callback !== 'function') throw new Error("Callback is not a function")
        let idx = this.listeners.indexOf(callback)
        if (idx !== -1) return
        this.listeners.push({
            callback
        })
    }

    // remove the callback for a label
    off(callback) {
        let idx = this.listeners.findIndex(l => l.callback === callback)
        if (idx === -1) return
        this.listeners.splice(idx,1)
    }

    // trigger the event with the label
    emit(...args) {
        for (let l of this.listeners) {
            try {
                l.callback(...args)
            }
            catch (e) {
                console.error("Error in event",this,l,args,e)
                throw e
            }
        }
    }
}
