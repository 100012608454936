import { processError } from "@/utils/errors";

export default class ErrorHandlers {
  constructor() {
    this.notifications = null
    this.debug = false
  }

  handleErrors() {
    window.onerror = function (msg, url, line) {
      //Ошибки JS
      console.error("Global Error", msg, url, line);
      this.showError(msg)
      return true // same as preventDefault
    }
    window.onunhandledrejection = (promRejEv) => {
      //Ошибки в promise
      console.error("Promise Error", promRejEv);
      this.showError(promRejEv.reason)
      return true // same as preventDefault
    }
  }
  
  handleVueErrors(vueApp) {
    vueApp.config.errorHandler = (err, vm, info) => {
      //Ошибки VUE
      console.error("Vue Error",err,vm,info)
      this.showError(err)
    }
  }

  showError(err) {
    if (!this.notifications) {
      alert( processError( err, this.debug ))
      return
    }
    this.notifications.add(
        processError( err, this.debug ),
        "danger",
        2000
    )
  }

}


