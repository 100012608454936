<template>
  <div class="flex-center"><Icon name="Loader" :size="size" /></div>
</template>
<script>
import Icon from './Icon.vue'
export default {
  name: "LoaderBlock",
  components: { Icon },
  props: {
    size: {
      type:    Number,
      default: 32
    }
  }
}
</script>