import { UserError } from "@/utils/errors"
import GatewayBase from "./api/gatewayBase"

export default class Yookassa {
  constructor() {
    /*** @type {GatewayBase} */
    this.gateway = null
    this.initialized = false
  }

  initWidget() {
    if (this.initialized) return
    return new Promise((resolve, fallback) => {
      var yookassaScript = document.createElement('script');
      yookassaScript.setAttribute('src','https://yookassa.ru/checkout-widget/v1/checkout-widget.js')
      yookassaScript.setAttribute('async',true)
      yookassaScript.onload = ()=> {
        this.initialized = true
        resolve()
      }
      document.head.appendChild(yookassaScript)
    })
  }

  async servicePay(params) {
    return this.gateway.serverHttp('POST','meddoc-yookassa-client/pay-service',{
      "returnUrl": this.getRootUrl(),
      ...params
      // "sendCheck": sendCheck,
      // "checkEmail": this.checkEmail,
      // "checkPhone": this.checkPhone,
      // "fio": this.profile.FULL_NAME
    })
  }  

  async subscriptionPay(params) {
    return this.gateway.serverHttp('POST','meddoc-yookassa-client/pay-subscription',{
      "returnUrl": this.getRootUrl(),
      ...params
      // "sendCheck": sendCheck,
      // "checkEmail": this.checkEmail,
      // "checkPhone": this.checkPhone,
      // "fio": this.profile.FULL_NAME
    })
  }

  getRootUrl() {
    return window.location.origin ?
      window.location.origin + '/' :
      window.location.protocol + '/' + window.location.host + '/'
  }

  show(confirmationToken) {
    return new Promise((resolve, fallback) => {
      
      console.log('window.YooMoneyCheckoutWidget',window.YooMoneyCheckoutWidget)
      this.yoomoney = new window.YooMoneyCheckoutWidget({
        confirmation_token: confirmationToken, //Токен, который перед проведением оплаты нужно получить от ЮKassa
        customization: {
          //Configuring how the widget is displayed
          modal: true
        },
        error_callback: (error)=>{
          console.error('YooMoneyCheckoutWidget Error',error)
        }
      })

      this.yoomoney.on('success', () => {
          //Пользователь успешно завершил оплату. Используется совместно с fail
          resolve()
      });
      this.yoomoney.on('fail', () => {
          //Пользователь неудачно завершил оплату. Используется совместно с success
          fallback(new UserError("Пользователь неудачно завершил оплату"))
      });
      this.yoomoney.on('complete', () => {
          //Пользователь завершил оплату. Событие наступает после успешной или неудачной оплаты.
      });
      this.yoomoney.on('modal_close', () => {
          //Пользователь закрыл всплывающее окно с платежной формой. Для тех, кто отображает виджет во всплывающем окне.
          fallback(new UserError("Пользователь отменил оплату"))
      });
      
      //this.yoomoney.render('yookassa')
      this.yoomoney.render()

    })
  }

}