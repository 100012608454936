import { reactive } from "vue";
import { saveAs } from 'file-saver';

export const MimeImage = ['image/jpeg','image/png','image/webp','image/gif']
//'image/svg+xml'
export const MimePdf = ['application/pdf']

export default class AttachModal {
  constructor() {
    this.api = null
    this.data = reactive({
      documentId: null,
      imageOpened: false,
      type: null,
      attach: null
    })
  }

  open(attach, documentId=null) {
    let newType = null
    if (MimeImage.includes(attach.MIME_TYPE)) newType = 'image'
    if (MimePdf.includes(attach.MIME_TYPE)) newType = 'pdf'
    //alert(newType)
    if (newType !== null) {
      this.data.documentId = documentId
      this.data.type = newType
      this.data.attach = attach    
      this.data.opened = true
    } else {
      this.download(attach)
    }
  }

  async download(attach) {
    let url = this.getDownloadUrl(attach.ATTACHMENT_ID,attach.ATTACH_FILENAME)
    //console.log("download",url,attach.ATTACH_FILENAME)
    const resp = await fetch(url)
    const blob = await resp.blob()
    saveAs(blob, attach.ATTACH_FILENAME)
  }

  getImageUrl(fileId, size) {
    return this.api.gateway.address + 'files/image/' + this.api.gateway.project + '/' +
        this.api.gateway.token + '/' + fileId + '/fit.'+size+'.jpg'
  }

  getDownloadUrl(fileId, fileName) {
    return this.api.gateway.address + 'files/download/' + this.api.gateway.project + '/' +
        this.api.gateway.token + '/' + fileId + '/' + encodeURI(fileName)
  }

  async getPdfInfo(fileId) {
    const url = this.api.gateway.address + 'files/pdf-info/' + this.api.gateway.project + '/' +
      this.api.gateway.token + '/' + fileId
    let resp = await fetch(url)
    return await resp.json()
  }

  getPdfPageUrl(fileId,pageNum) {
    return this.api.gateway.address + 'files/pdf/' + this.api.gateway.project + '/' +
      this.api.gateway.token + '/' + fileId + '/' + pageNum
  }

}