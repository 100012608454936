import escapeXml from "./escapeXml"

/** Ошибка пришла с сервера */
export class ServerError extends Error {
    constructor(message, name="ServerError", payload=null, logId=null) {
        super(message)
        this.name = name
        this.payload = payload
        this.logId = logId
    }
}
/** Детальная ошибка запроса */
export class QueryError extends Error {
    constructor(message, name, params={}, logId=null) {
        super(message)
        this.name = name
        this.params = params
        this.logId = logId
    }
}
/** Ошибка пользователя - выводится на экран */
export class UserError extends Error {}
/** Ошибка сессии - надо переавторизоваться */
export class SessionError extends Error {}
/** Ошибка соединения - надо дождаться соединения */
export class ConnectionError extends Error {}

export let processError = function(err, isDebug=true) {
    //console.error('Global Error:', err);
    //let message = 'Произошла внутренняя ошибка';
    let message = null;
    //Просто строка
    if (isDebug && typeof(err)==='string') {
        message = 'Error:<br>'+err;
    }
    
    if (err instanceof UserError) {
        message = err.message
    
    //Объект с аппсервера
    } else if (typeof(err)==='object' && err.SymbCode) {
        message = 'Произошла внутренняя ошибка в серверной части';
        if (isDebug && typeof(err.Message)==='string') {
            message = 'Server Error:<br>'+err.Message;
        }
        if (isDebug && err.SymbCode === 'QUERY_ERROR') {
            message = 'Query Error '+err.query+':<br>'+escapeXml(JSON.stringify(err.queryParams))+'<br>'+escapeXml(err.Message);
        }
        if (err.SymbCode === 'INVALID_TOKEN') {
            message = null;
            store.commit('session/clear');
        }
        if (err.SymbCode === 'QUERY_USER_ERROR') {
            message = err.Message;
        }
    
    //GatewayError
    } else if (err instanceof ServerError) {
        if (err.name === "InvalidToken") {
            message = null;
            store.commit('session/clear')
        } else {
            message = `Server Error (${err.name}): ${err.message}`
        }

    //Объект JS эксепшн
    } else if (typeof(err)==='object' && typeof(err.message)==='string') {
        message = err.message
        if (message === "ResizeObserver loop limit exceeded") {
            message = null
        }
        if (!isDebug) {
            message = null
        }
    }

    return message;
}