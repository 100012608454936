export default class HeightFix {
  constructor() {
    this.calc()
    window.addEventListener('resize', () => this.calc() );    
  }

  calc() {
    let innerHeight = window.innerHeight
    //document.documentElement.style.setProperty( `--inner-height`, innerHeight+'px' )
    document.body.style.height = innerHeight+'px'
  }
}

