<template>
  <p class="cb-icon" :style="elStyle"></p>
</template>
<script>
const icons = require.context('./icons')
const iconNames = icons.keys()

export default {
  name: "Icon",
  props: {
    name: String,
    svg: String,
    size: Number
  },
  computed: {
    elStyle() {
      if (!this.size) return {}
      return {
        width: this.size+'px',
        height: this.size+'px'
      }
    }
  },
  mounted() {
    //console.log("Icon",this.name)
    if (this.name) {
      let svgPath = `./${this.name}.svg`
      if (!iconNames.includes(svgPath)) {
        throw new Error(`Icon ${this.name} not found`)
      }
      this.loadSvg(icons(svgPath))
    } else if(this.svg) {
      this.loadSvg(this.svg)
    } else {
      throw new Error(`Icon attr not set`)
    }
  },
  methods: {
    loadSvg(svgStr) {
      //console.log("loadSvg",svgStr)
      const parser = new DOMParser()
      const svgDoc = parser.parseFromString(svgStr, "image/svg+xml")
      let svg = svgDoc.getElementsByTagName('svg')
      //console.log("getElementsByTagName",svgDoc,svg)
      this.$el.appendChild(document.importNode(svg[0], true))
      //this.$el.appendChild(svg[0])
    }
  }
}
</script>
<style lang="sass">
.cb-icon
  display: flex
  svg
    width: 100%
    height: 100%
</style>