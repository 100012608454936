import Api from './api';
import Session from './session'
import { reactive, ref } from "vue"
import AppApi from './appApi'
import CarabiAdapter from './carabi-adapter'
import Theme from './theme'
import UserAgent from './user-agent'
import Profile from './profile'
import Eventer from './eventer'
import Notifications from './notifications'
import UsersCache from './users-cache';
import CardApi from './cardApi';
import AttachModal from './attachModal';
import FileStorageMclStorage from './storage';
import ContactView from './contactView';
import ErrorHandlers from './errorHandlers';
import HeightFix from './heightFix';
import Yookassa from './yookassa'
import Popups from './popups';
import Modals from './modals';
import Reminder from './reminder';
import Document from './document';

export default class Backend {

  constructor() {
    this.config = null
    this.loaded = false
    this.authorised = ref(false)
    /** @type {Api} */
    this.api = new Api()
    this.adapter = new CarabiAdapter(this.api)
    this.cardApi = new CardApi()
    this.session = new Session()
    this.appApi = new AppApi()
    this.theme = new Theme()
    this.userAgent = new UserAgent()
    this.eventer = new Eventer()
    this.notifications = new Notifications()
    this.popups = new Popups()
    this.usersCache = new UsersCache()
    this.profile = new Profile(null,this.usersCache)
    this.attachModal = new AttachModal()
    this.storage = new FileStorageMclStorage()
    this.contactView = new ContactView()
    this.errorHandlers = new ErrorHandlers()
    this.heightFix = new HeightFix()
    this.yookassa = new Yookassa()
    this.modals =  new Modals()
    this.reminder =  new Reminder(this.usersCache)
    this.document = new Document(this.api)
  }

  async loadConfig() {
    try {
        let response = await fetch('/config.json')
        return await response.json()
    } catch(e) {
        throw new Error('Failed to load config.json: '+e.message)
    }    
  }

  async init() {
    this.config = await this.loadConfig()
    await this.theme.load()
    this.api.init({
      ...this.config,
      api: {
        queries: "gateway",
        auth: "gateway"
      }
    })

    this.errorHandlers.notifications = this.notifications
    this.errorHandlers.debug = this.config.debug || false    
    this.errorHandlers.handleErrors()
    this.profile.queries = this.api.queries
    this.usersCache.attach = this.attachModal
    this.usersCache.queries = this.api.queries
    this.cardApi.queries = this.api.queries
    this.reminder.queries = this.api.queries
    this.reminder.config = this.config
    this.attachModal.api = this.api
    this.storage.gateway = this.api.gateway
    this.yookassa.gateway = this.api.gateway

    this.session.load()

    this.eventer.address = this.config.eventer
    
    //Загрузка токена из рамы
    let appToken = await this.appApi.getCarabiToken()
    if (appToken) {
      console.log("set appToken from appApi.getCarabiToken",appToken)
      this.session.data = {
        token: appToken
      }
      this.session.save()
    }

    let token = this.session.data?.token
    if (!token) {
      this.loaded = true
      return
    }
    this.api.setToken(token)
    
    try {
      //Профиль
      await this.profile.load()
    } catch(e) {
      console.log("Get Profile Failed", e)
      await this.logout()
      this.loaded = true
      return
    }

    this.eventer.token = token
    this.eventer.enable()
    await this.eventer.waitConnect()

    this.authorised.value = true
    this.loaded = true
  }

  async logout() {
    this.eventer.disable()
    this.session.clear()
    await this.api.logout()
    this.appApi.deleteToken()
    this.authorised.value = false
  }

  async auth(login, password) {
    await this.api.login(login, password)
    
    //Сохраним токен
    let token = this.api.getToken()
    this.appApi.setCarabiToken(token)
    this.session.data = { token }
    this.session.save()
    
    await this.profile.load()
    this.eventer.token = token
    this.eventer.enable()
    await this.eventer.waitConnect()
    this.authorised.value = true
  }

  checkRoute(route) {    
    console.log("checkRoute", route.meta, this.authorised.value)
    let meta = route.meta || {}

    //Авторизация
    if (!this.authorised.value && route.name !== 'Auth') {
      return '/auth'
    }
    //Регистрация
    let registred = !!this.profile.data.value.IS_REGISTERED
    if (this.authorised.value && !registred && route.name !== 'Registration') {
      return '/registration'
    }
    //Подписка
    let subscribed = !!this.profile.data.value.IS_PAYED_SUBSCRIBE
    if (this.authorised.value && !subscribed && route.name !== 'Subscribe') {
      return '/subscribe'
    }

    return null
  }

  openMainMenu() {}

}