<template>
<Modal v-if="userId" 
  @closed="closed"
  title="Профиль контакта"
  :large="true"
  :auto-open="true"
  :width="800"
  >
  <ContactProfile :userId="userId" />
</Modal>
</template>
<script>
import { backend } from '@/main'
import Modal from '@/modals/Modal'
import ContactProfile from '@/components/ContactProfile'

export default {
  name: "ContactProfileModal",
  components: {
    Modal,
    ContactProfile
  },
  computed: {
    userId() {
      return backend.contactView.data.userId
    }
  },
  methods: {
    closed() {
      backend.contactView.data.userId = null
    }
  }
}
</script>