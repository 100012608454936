<template>
  <div v-if="!loaded" class="flex-grow" style="display: flex; justify-content: center; align-items: center;">
    <Loader :size="128" />
  </div>    
  <div v-else class="flex-grow flex-column">
    <Header :user="user" :compact="compact" />
    <doc-card-tabs v-if="groups" :cardModel="cardModel" :documentInfo="cardModel.info" :groups="groups"  />
  </div>        
</template>
<script>
import Icon from '@/components/ui/Icon.vue'
import Loader from '@/components/ui/Loader.vue'
import { backend } from '@/main'

import Header from './header.vue'
import CardModel from '@/card/cardModel'
import DocCardTabs from '@/card/DocCardTabs.vue'

export default {
  name: "ContactProfile",
  components: {
    Loader,
    Icon,
    Header,
    DocCardTabs
//    FooterSend,
//    Messages
  },
  props: {
    userId: Number,
    compact: Boolean
  },
  data() {
    return {
      loaded: false,
      user: null,
      profile: null,
      //curProfileTabId: null,
      cardInfo: null,
      cardModel: null,
      groups: []
    }
  },
  computed: {
  },
  watch: {
    async userId() {
      this.loaded = false
      await this.reload()
    }
  }, 
  async created() {
    await this.reload()    
  },
  beforeUnmount() {
    //this.model.destroy()
  },
  methods: {

    async reload() {
      this.user = await backend.usersCache.get(this.userId)
      //this.profile = await backend.cardApi.getDocument( this.user.profile_id )
      //if (this.profile.length) this.curProfileTabId = this.profile[0].id
      this.cardModel = new CardModel({
        cardId: this.user.profile_id,
        readOnly: true
      })
      await this.cardModel.reload()
      this.cardInfo = await backend.api.queries.selectRow("MD_GET_PROFILE_CARD_ID", {
        PROFILE_ID : this.user.profile_id, //number
	      PURPOSE : "CONTACT" //varchar
      })
      this.groups = await backend.api.queries.select('CRM_CARD_VIEW_GROUPS', {
        document_id: this.user.profile_id,
        view_id : this.cardInfo.OBJECT_VIEW_ID //number
      }, 1000)      
      this.loaded = true
    },

  }
}
</script>