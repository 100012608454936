import { reactive, ref } from "vue"
import Queries from "./api/queries/index"
import { UserError } from "@/utils/errors"

export default class Profile {
  constructor(queries,usersCache) {
    /** @type {Queries} */
    this.queries = queries
    this.usersCache = usersCache
    this.data = ref({})
    this.contact = ref({})
  }

  async load() {
     let profile = await this.queries.selectRow("MD2_GET_CURRENT_SYSTEM_USER", {})
     if (!profile) throw new UserError("Профиль пользователя не найден")
     this.data.value = profile
     this.contact.value = await this.usersCache.get(this.data.value.USER_ID)
  }
}