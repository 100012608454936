/** Связь с рамой */
export default class AppApi {

  getCarabiToken() {
    if (this.appType === null) {
      return null
    }
    return new Promise((resolve, reject) => {
  
      window.setCarabiTokenToJs = (token) => {
        window.setCarabiTokenToJs = null
        console.log('setCarabiTokenToJs', token)
        resolve(token)
      }
  
      this.run('tryGetCarabiToken', '', 0)  
    })
  }

  // сохранить токен авторизации в конфиг приложения
  setCarabiToken ( token = null ) {
    console.log('setCarabiToken')
    this.run('trySetCarabiToken', token, 1)
  }

  //Передать токен приложению
  setToken ( token ) {
    if (token) {
      this.run('setToken', token, 1)
    }
  }  

  //Убрать токен из приложения
  deleteToken () {
    this.run('deleteToken', '', 1)
  }

  //Получить права на аплоуд
  checkFilePermissions () {
    console.log('AppApi checkFilePermissions')
    this.run('checkFilePermissions', null, 0)
  }

  getPhoneContacts () {
    if (this.appType === null) {
      return null
    }
    return new Promise((resolve, reject) => {

      window.setContacts = (result) => {
        window.setContacts = null
        console.log('APP setContacts', result)
        resolve(result)
      }
      this.run('tryGetContacts', {}, 0)

    })
  }

  /**
   * Проверка подписки в приложении
   * @param type
   */
  checkSubscription ( type ) {
    this.run('tryGetSubscription', type, 1)
  }

  /*
  * Сделать вызов по телефону
  */
  callPhone ( phoneNum ) {
    location.href = 'tel:+'+phoneNum
    /*
    if (this.appType === 'ios') {
      this.run('callNumber', phoneNum, 1)
    } else {
      //window.open('tel:+'+phoneNum)
      location.href = 'tel:+'+phoneNum
    }
    */
  }  

  /**
   * проверка наличия покупки в чате
   * @param doctorLogin
   * @param patientLogin
   */
  checkMessagePack ( doctorLogin, patientLogin ) {
    if (this.appType === 'android') {
      app.tryGetMessagePack(doctorLogin, patientLogin)
    }
    else if (this.appType === 'ios') {
      let handler = window.webkit.messageHandlers.tryGetMessagePackHandler
      handler.postMessage({ doctorLogin, patientLogin })
    }
    //this.run('tryGetMessagePack', { doctorLogin, patientLogin }, 1)
  }

  setMessagePack ( doctorLogin, patientLogin, iappId ) {
    if (this.appType === 'android') {
      app.tryAcquireMessagePack(doctorLogin, patientLogin, iappId)
    }
    else if (this.appType === 'ios') {
      let handler = window.webkit.messageHandlers.tryAcquireMessagePackHandler
      handler.postMessage({ doctorLogin, patientLogin, iappId })
    }
    //this.run('tryAcquireMessagePack', { doctorLogin, patientLogin, iappId }, 1)
  }

  run ( name, param, paramCount ) {
    console.log('AppApi run: ', name)
    try {

      if (this.appType === 'android') {
        console.log('AppApi run android', name, app)
        if (app[ name ]) {
          console.log('AppApi run: app method exec', name, app[ name ], param, paramCount)
          if (paramCount) {
            console.log('app[name](param)', `app.${ name }(${ param })`, paramCount)
            app[ name ](param)
          }
          else {
            console.log('app[name]()', `app.${ name }()`)
            app[ name ]()
          }
        }
        else {
          console.log('AppApi run: app method not found', name)
        }
      }

      else if (this.appType === 'ios') {
        let handler = window.webkit.messageHandlers[ name + 'Handler' ]
        console.log('IOS messageHandlers', `${ name }Handler`, param, paramCount)
        console.log('handler', handler)
        if (handler) {
          if (paramCount) {
            handler.postMessage(param)
          }
          else {
            handler.postMessage({})
          }
        }
        else {
          console.log('AppApi run: handler not found', name + 'Handler')
        }
      }

    }
    catch (e) {
      console.error('AppApi run error: ', e)
    }
  }

  constructor () {
    this.appType = null
    if (typeof ( app ) !== 'undefined') {
      this.appType = 'android'
    }
    if (window.webkit !== undefined) {
      this.appType = 'ios'
    }
    console.log('AppApi appType', this.appType)
  }
}