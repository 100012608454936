import AsyncCachedList from "@/utils/async-cached-list"

export default class UsersCache extends AsyncCachedList {
  constructor() {
    super()
    this.attach = null
    this.queries = null
  }

  async load(id) {
    const rawContact = await this.queries.selectRow( 'CHAT_GET_USER_INFO', { USERS: id } )
    let result = {}
    for (let i in rawContact) {
      result[i.toLowerCase()] = rawContact[i]
      result.fullName = [ rawContact.LAST_NAME, rawContact.FIRST_NAME, rawContact.MIDDLE_NAME ].filter( i => i ).join( ' ' )
      result.avatar_url = null
      if (result.photo_id) {
        result.avatar_url = this.attach.getImageUrl(result.photo_id,'256x256')
      }
    }
    return result
  }

}