<template>
  <div class="flex-grow">
    <ul ref="imgs" v-if="loaded" style="display:none">
      <li v-for="i,n in images" :key="i"><img :src="i" :alt="(n+1)+'/'+pdfInfo.pageCount" /></li>    
    </ul>
  </div>
  </template>
  <script>
  import Viewerjs from 'viewerjs'
  import { backend } from '@/main'
  
  export default {
    name: "PdfView",
    props: {
      attach: Object
    },
    data() {
      return {
        loaded: false,
        pdfInfo: null,
        images: []
      }
    },

    computed: {
    },

    async mounted() {
      this.pdfInfo = await backend.attachModal.getPdfInfo(this.attach.ATTACHMENT_ID)
      this.images = []
      for (let i=1; i<=this.pdfInfo.pageCount; i++) {
        this.images.push(backend.attachModal.getPdfPageUrl(this.attach.ATTACHMENT_ID,i))
      }
      this.loaded = true
      await this.$nextTick()
      this.initViewer()
    },

    beforeUnmount() {
      if (this.viewer) this.viewer.destroy()
    },

    methods: {
      initViewer() {
        this.viewer = new Viewerjs(this.$refs.imgs, {
          inline: true,
          navbar: false,
          title: (image, imageData) => `${image.alt}`,
          fullscreen: false,
          button: false,
          backdrop: false,
          transition: false,
          toolbar: {
            zoomIn: 1,
            zoomOut: 1,
            oneToOne: 0,
            reset: 0,
            prev: 1,
            play: 0,
            next: 1,
            rotateLeft: 0,
            rotateRight: 0,
            flipHorizontal: 0,
            flipVertical: 0,        
          },      
        })
      },
    }
  }
  </script>
  <style lang="sass">
    @import 'viewerjs/src/index.css'
  </style>