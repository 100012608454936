import { computed, ref } from "vue"

export default class Notifications {
  constructor() {
    this.messages = ref([])
    this.messagesCount = computed(()=>{
      return this.messages.value.length
    })
    this.lastMessage = computed(()=>{
      if (this.messagesCount.value === 0) return null
      return this.messages.value[ 0 ]
    })
  }

  closeLast() {
    this.messages.value.splice( 0, 1 )
  }

  closeAll() {
    this.messages.value = []
  }

  /**
   * 
   * @param {String} content 
   * @param {String} type info, danger or warning
   * @param {Number} delay in ms
   * @param {Function} action 
   * @returns 
   */
  add( content, type='info', delay=5000, action=null ) {
    if ( !content ) return
    const message = { content, type, delay, action }
    this.messages.value.push( message )
  }
}