<template>
  <div class="pd-video-conf"
       :class="{'pd-video-conf--small': small}">

    <div class="pd-video-conf__size">
      <button class="pd-video-conf__button" @click="small = !small" v-html="icon"></button>
    </div>

    <!--allow="camera; microphone; fullscreen; speaker; display-capture"-->
    <iframe ref="videoConf" class="pd-video-conf__frame" allow="camera; microphone; autoplay" :src="url" />

    <div class="pd-video-conf__close">
      <button class="pd-video-conf__button pd-video-conf__button--close" @click="callOff"
              v-html="icons.callOff"></button>
    </div>
  </div>
</template>

<script>
import { backend } from '@/main.js'

export default {
  name: 'VideoConf',
  props: {
    data: Object
  },

  data() {
    return {
      baseUrl: 'https://conf.docsme.ru/ng/',
      small:   false,
      icons:   {
        callOff:  `<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <line x1="3" y1="21" x2="21" y2="3" />
  <path d="M5.831 14.161a15.946 15.946 0 0 1 -2.831 -8.161a2 2 0 0 1 2 -2h4l2 5l-2.5 1.5c.108 .22 .223 .435 .345 .645m1.751 2.277c.843 .84 1.822 1.544 2.904 2.078l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a15.963 15.963 0 0 1 -10.344 -4.657" />
</svg>`,
        maximize:
                  `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-maximize" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M4 8v-2a2 2 0 0 1 2 -2h2" />
          <path d="M4 16v2a2 2 0 0 0 2 2h2" />
          <path d="M16 4h2a2 2 0 0 1 2 2v2" />
          <path d="M16 20h2a2 2 0 0 0 2 -2v-2" />
        </svg>`,
        minimize: `<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-minimize" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M15 19v-2a2 2 0 0 1 2 -2h2" />
          <path d="M15 5v2a2 2 0 0 0 2 2h2" />
          <path d="M5 15h2a2 2 0 0 1 2 2v2" />
          <path d="M5 9h2a2 2 0 0 0 2 -2v-2" />
        </svg>`
      }
    }
  },

  created() {
  },

  computed: {
    icon() {return ( this.small ) ? this.icons.maximize : this.icons.minimize},
    url() {
      return backend.reminder.getVideoChatUrl(this.data)
    }
  },

  methods: {
    async callOff() {
      if (!await backend.popups.confirm('Завершить Видеочат?')) return
      await backend.reminder.closeVideoChat()
    }
  }
}
</script>

<style lang="scss">
@import "@/sass/variables";
@import "@/sass/mixins";

$buttonSize: 3rem;

.pd-video-conf {
  position:         fixed;
  z-index:          100;
  background-color: var(--color-background);
  width:            100%;
  height:           100%;
  overflow:         hidden;

  &--small {
    //width: 40vw
    //padding-top: calc(100% / 4 * 3)
    width:         320px;
    height:        350px;
    border-radius: 1rem 0 0 1rem;
    right:         0;
    //height: auto
    top:           50%;
    transform:     translateY(-50%);
    box-shadow:    var(--shadow-large);
  }

  &__size {
    position: absolute;
    top:      0;
    left:     0;
    margin:   $global-margin;
  }

  &__frame {
    height: 100%;
    width:  100%;
    border: none;
  }

  &__close {
    position:      absolute;
    bottom:        $global-margin;
    left:          50%;
    margin-bottom: $global-margin-large;
    transform:     translateX(-50%);
  }

  &__button {
    background-color: rgba(0, 0, 0, .3);
    border-radius:    500px;
    width:            $buttonSize;
    height:           $buttonSize;
    padding:          0;
    display:          flex;
    align-items:      center;
    justify-content:  center;
    color:            rgba(255, 255, 255, 1);

    &--close {
      background-color: var(--color-danger);
      width:            $buttonSize + 1.2rem;
      height:           $buttonSize + 1.2rem; }
  }

  & > * {
    width:  $buttonSize * .7;
    height: $buttonSize * .7; }

  & > iframe {
    width:  100%;
    height: 100%;
  }
}
</style>