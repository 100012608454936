<template>
  <div v-if="loaded" class="pd-sidebar-profile" @click="showProfile">

    <!--div class="pd-sidebar-profile__photo">
      <img v-if="avatarUrl" :alt="profile.FIO" :src="avatarUrl" height="50" width="50">

      <svg v-else height="50" viewBox="0 0 50 50" width="50" xmlns="http://www.w3.org/2000/svg">
        <rect fill="#dedede" height="50" width="50" />
        <text dy="8" fill="rgba(0,0,0,0.6)" font-family="sans-serif" font-size="20" font-weight="bold"
              text-anchor="middle" x="50%" y="50%">{{ getFirstLetter( fio ) }}
        </text>
      </svg>
    </div-->

    <UserAva :size="50" :contact="contact" />

    <div class="pd-sidebar-profile-name">
      <div class="pd-sidebar-profile-name__surname" v-text="contact.last_name"></div>
      <div class="pd-sidebar-profile-name__name">
        <span v-text="contact.first_name"></span>
        <span v-text="contact.middle_name"></span>
      </div>

      <small>открыть профиль</small>
    </div>
  </div>
</template>

<script>
import { backend } from '@/main'
import UserAva from '@/components/ui/elements/UserAva.vue'

export default {
  name: 'SideBarUser',
  components: {UserAva},
  data() {
    return {
      loaded: false,
      //contact: null
    }
  },

  computed: {
    contact() {
      return backend.profile.contact.value
    }
  },

  async created() {
    // console.log('prof',backend.profile.data.value)
    // this.contact = await backend.usersCache.get(
    //   backend.profile.data.value.USER_ID
    // )
    this.loaded = true
  },

  methods: {

    showProfile() {
      this.$router.push( { name: 'MyProfile' } )
      this.$emit("close")      
    },

    /*getFirstLetter( item ) {
      return getFirstLetter( item )
    },

    getName( item ) {
      return getName( item )
    },

    getPhoto( id ) {
      return getImage( id )
    }*/
  }
}
</script>