<template>
  <div class="pd-main">
    <VideoConf v-if="showVideoChat" :data="showVideoChat" />
    <CallPanel />
    <!--transition name="sidebar" mode="in-out" v-show="sidebarOpen" appear></transition-->
    <SideBar @close="sidebarOpen=false" />    
    <div class="pd-sidebar-outer" key="online" :class="{active: sidebarOpen}">
      <slot></slot>
      <!--transition name="fade" mode="in-out" appear></transition-->
      <div class="pd-sidebar-outer__opacity" v-if="sidebarOpen" @click="sidebarOpen=false"></div>      
    </div>

  </div>
</template>
<script>
import {backend} from '@/main'
import SideBar from './SideBar.vue'
import CallPanel from './CallPanel'
import VideoConf from './VideoConf'

export default {
  name: 'Layout',

  components: {
    SideBar,
    CallPanel,
    VideoConf
  },

  data() {
    return {
      sidebarOpen: false
    }
  },  

  computed: {
    doctor() {return true},//this.$store.getters[ 'userType' ] === 1},
    accountBalance() {return 1},//this.$store.getters[ 'accountBalance' ]},
    showVideoChat() { return backend.reminder.showVideoChat.value }
  },  

  created() {
  },

  mounted() {
    backend.openMainMenu = ()=>{ this.sidebarOpen = true }
  },

  beforeUnmount() {
    backend.openMainMenu = ()=>{}
  },

  beforeUnmount() {    
  },

  watch: {
  },

  methods: {

    initAppEvents() {
      window.appConnect = () => {
        console.log( 'appConnect' )
      }
      window.appDisconnect = () => {
        console.log( 'appDisconnect' )
      }
    },

    async reload() {
    },

    swipeLeftHandler( direction ) {
      //if ( direction === 'left' ) this.$store.commit( 'sidebarOpenToggle', false )
    },
    swipeRightHandler( direction ) {
      //if ( direction === 'right' ) this.$store.commit( 'sidebarOpenToggle', true )
    }

  }
}
</script>