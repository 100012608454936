<template>
  <Modal ref="modal" @closed="$emit('closed')" :title="title" :width="500" :auto-open="true">
  <div class="pd-video-conf-add">
    <LoaderBlock v-if="step === 0" />
    <CurrentSlot v-if="step === 1" @close="$refs.modal.close()" @next="step = 2" :bookSlot="curSlot" :visitType="visitType" />
    <SelectDay v-if="step === 2" @select="selectDay($event)" @close="$refs.modal.close()" :doctor="doctor" :visitType="visitType" />
    <SelectSlot v-if="step === 3" @select="selectSlot($event)" @back="step = 0" :doctor="doctor" :day="curDate" :process="process" :visitType="visitType" />
    <Success v-if="step === 4" @close="$refs.modal.close()" :bookSlot="curSlot" :visitType="visitType" />
  </div>
  </Modal>
</template>
<script>
import Modal from '@/modals/Modal'
import CurrentSlot from './CurrentSlot.vue'
import SelectDay from './SelectDay.vue'
import SelectSlot from './SelectSlot.vue'
import Success from './Success.vue'
import LoaderBlock from '@/components/ui/LoaderBlock.vue'
import { backend } from '@/main'

export default {
  name: "NewVisit",
  props: {
    visitType: Number,
    doctor: Object,
    roomId: { type:Number, default:null }
  },  
  components: {
    Modal,
    CurrentSlot,
    SelectDay,
    SelectSlot,
    Success,
    LoaderBlock
  },
  data:()=>({
    step: 0,
    curDate: null,
    curSlot: null,
    process: false
  }),
  computed: {
    title() {
      return (this.visitType === 2)? 'Запланировать Видеочат' : 'Записаться на прием'
    }
  },
  async created() {
    if (this.visitType !== 2 || !this.roomId) {
      this.step = 2
      return
    }
    this.curSlot = await backend.api.queries.selectRow( 'MD_CHECK_ROOM_FOR_VC', { room_id: this.roomId } )
    if (this.curSlot) {
      this.step = 1
    } else {
      this.step = 2
    }
  },
  methods: {
    selectDay(newDate) {
      this.curDate = newDate
      this.step = 3
    },

    async selectSlot(newSlot) {
      this.process = true
      try {
        const query   = ( this.visitType === 2 ) ? 'MD_BOOK_PATIENT_WS' : 'MD_BOOK_PATIENT_VISIT'
        const params  = {
          doctor_id:      this.doctor.profile_id,
          room_id:        this.roomId,
          work_time_from: newSlot.time,
          clinic_id:      newSlot.clinic,
          filial_id:      newSlot.filial_id
        }
        //console.log( query, params )
        await backend.api.queries.query( query, params )
        
      } finally {
        this.process = false
      }

      //setTimeout( () => this.$store.commit( 'closePanel' ), 4000 )
      this.curSlot = newSlot
      this.step = 4
    }

  }
}
</script>
<style lang="sass">
  .pd-video-conf-add
    padding: 2rem 1rem
    overflow: hidden
    height: 100%
  .pd-video-conf-title
    line-height: 1.4
    text-align: center
    margin: 0 auto
    max-width: 25rem
  .pd-video-conf-wrap
    //height: 100%
    display: flex
    flex-direction: column
    gap: 2rem
    overflow: hidden
    align-items: center
  .pd-video-conf-days
    flex-direction: column
    display: flex
    gap: 0.25rem
  .pd-video-conf-day
    border: 1px solid transparent
    flex: none
    padding: 0 1rem
    border-radius: 5px
    cursor: pointer
    display: flex
    align-items: center
    gap: 0.5rem
    &--selected
      border-color: var(--color-success)
      color: var(--color-success)
</style>