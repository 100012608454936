import dayjs from "dayjs"
import customParseFormat from 'dayjs/plugin/customParseFormat.js'
import utc from 'dayjs/plugin/utc.js'
import timezone from 'dayjs/plugin/timezone.js'
import relativeTime from 'dayjs/plugin/relativeTime.js'
import isToday from 'dayjs/plugin/isToday'
import dayjsRu from 'dayjs/locale/ru'
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(isToday)
dayjs.locale(dayjsRu)

import { createApp } from 'vue'
import App from './App.vue'
import router from './routes'

import Backend from './backend'
export const backend = new Backend()
export const carabi = backend.adapter

const app = createApp(App)

import Icon from './components/ui/Icon.vue'
app.component('Icon', Icon)

backend.errorHandlers.handleVueErrors(app)
app.use(router).mount('#vue_app')
