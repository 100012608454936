<template>
  <div class="pd-sidebar-outer flex-center" style="">
    <div class="start-error">
      <Icon name="Error" style="max-width:100px" />
      <h3 class="margin-top" v-text="'Произошла ошибка во время запуска приложения'"></h3>
      <div class="text-muted">{{error.message}}</div>
      <div style="margin-top: 50px">
        <button @click="logout" class="pd-button warning">Выход</button>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from '@/components/ui/Icon.vue'
import { backend } from '@/main'
export default {
  components: {Icon},
  props: {
    error: Object
  },
  methods: {
    logout() {
      backend.logout()
      location.reload()
    }
  }
}
</script>
<style lang="sass">
.start-error
    max-width: 300px
    display: flex
    flex-direction: column
    align-items: center
    text-align: center
</style>
