<template>

  <div v-if="loaded && days.length > 0" class="pd-video-conf-wrap">
    <h2 class="pd-video-conf-title"
        v-text="'Выберите удобный день приема'"></h2>

    <div class="pd-video-conf-days pd-video-conf-wrap__body">
      <DatePicker mode="date" v-model="calendarDay" @dayclick="selectDate"
                   :is-expanded="true"
                   :attributes="attrs"
      >
      </DatePicker>

    </div>

    <button class="pd-video-conf-wrap__footer pd-button not-pill success"
            :disabled="!canSubmit"
            @click="submit"
            v-text="'Выбрать'"></button>
  </div>

  <div v-else-if="loaded" class="pd-video-conf-wrap">
    <div class="pd-video-conf-wrap__body flex center middle">
      <h2 class="pd-video-conf-title" v-text="'К сожалению нет доступных дней для записи'"></h2>
    </div>
    <button class="pd-video-conf-wrap__footer pd-button not-pill success"
            @click="$emit('close')"
            v-text="'Закрыть'"></button>
  </div>

</template>

<script>
import 'v-calendar/style.css';
import { carabiDate, carabiDateFormat, carabiToISO, getWeek, isTodayDate, utfDate } from '@/utils/date-utils'
import {DatePicker} from 'v-calendar'
import { backend } from '@/main';
import dayjs from 'dayjs';

export default {
  name: 'NewVisitSelectDay',

  props: {
    visitType: Number,
    doctor: Object
  },  

  components: {
    DatePicker
  },

  data() {
    return {
      loaded: false,
      days: [],
      day:         null,
      calendarDay: null,
      disabled: false,
    }
  },

  computed: {

    minDate() {
      return this.days[0]?.WS_DAY
    },

    availableDays() {
      return this.days.map( i => dayjs(i.WS_DAY).startOf('day') )
    },

    attrs() {
      return this.days.map( i => ({
        highlight: {
          fillMode: 'light',
          color:    'blue'
        },
        popover:   {
          label:         i.FILIAL_NAME,
          visibility:    'hover',
          hideIndicator: true
        },
        dates: i.WS_DAY
      }))
    },

    canSubmit() {
      if (!this.calendarDay) return false
      let i = this.availableDays.findIndex( ad => ad.isSame(this.calendarDay, 'day'))
      //console.log("!!!",i)
      return i !== -1
    }
  },

  watch: {
  },

  async created() {
    await this.loadDays()
  },

  methods: {
    selectDate(param) {
      //console.log("!!!",param)
      this.calendarDay = param.startDate
    },

    submit() {
      if ( !this.canSubmit ) return
      this.$emit( 'select', this.calendarDay )
    },

    async loadDays() {
      //this.disabled = true
      const result  = await backend.api.queries.select( 'MD_GET_DOCTOR_WS_DAYS', {
        doctor_id: this.doctor.profile_id,
        visit_type: this.visitType
      }, 200 )
      this.days = result
      //console.log( 'result', result )
      this.loaded = true
      //this.disabled = false
    },    
  }
}
</script>

