<template></template>
<script>
import {backend} from '../main.js'
export default {
  data:()=>({
  }),
  async created() {
    let authorized = backend.authorised.value
    if (!authorized) {
      this.$router.push({ name: 'auth' })
    } else {
      this.$router.push({ name: 'Chats' })
    }
  }
}
</script>