import dayjs from "dayjs"
export function getTimeFromNow( date, todayTime = false, withoutSuffix = false ) {
  //const parseDate = dayjs( date, 'DD.MM.YYYY HH:mm:ss' )
  const parseDate = dayjs( date )
  const dateDay   = parseDate.format( 'YYYY.MM.DD' )
  const todayDay  = dayjs().format( 'YYYY.MM.DD' )
  const isToday   = ( dateDay !== todayDay || todayTime )
    ? parseDate.fromNow( withoutSuffix )
    : 'сегодня'
  return ( parseDate.isValid() ) ? isToday : null
}

export function formatShortTime( date ) {
  let parsedDate = date
  if (typeof parsedDate === "string") {
    parsedDate = parseDate( date )
  }
  if (!parsedDate.isValid()) return ''
  //console.log(this.item.LAST_MESSAGE_DATE, parsedDate.isToday(), parsedDate.format("HH:mm DD MMM YY"))
  if (parsedDate.isToday()) {
    return parsedDate.format("HH:mm")
  } else {
    let newDate = parsedDate.format("DD MMM")
    let y = parsedDate.format("YY")
    if (y !== dayjs().format("YY")) {
      newDate += ' '+y
    }
    return newDate
  }
}

export function parseDate(str) {
  let parsedDate = dayjs( str, 'DD.MM.YYYY HH:mm:ss', true )
  if (!parsedDate.isValid()) {
    parsedDate = dayjs( str )    
  }
  return parsedDate
}

/**
 * parseTime 12.04.2020 09:12:00 => 09:12
 * @param date
 * @returns {string}
 */
export function getTime( date ) {
  const parseDate = dayjs( date, 'DD.MM.YYYY HH:mm:ss' )
  return ( parseDate.isValid() ) ? parseDate.format( 'HH:mm' ) : null
}

export function carabiDateOnly( date ) {
  //dayjs.locale( 'ru' )
  return dayjs( date ).format( 'DD.MM.YYYY' )
}

export function carabiToISO( date ) {
  return dayjs( date, 'DD.MM.YYYY HH:mm:ss' ).toISOString()
}

export function formatToDate( date, formatOut ) {
  const parseDate = dayjs( date )
  //console.log('parseDate - ', date, formatOut, parseDate.format(formatOut))
  return ( parseDate.isValid() ) ? parseDate.format( formatOut ) : null
}