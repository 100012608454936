<template>
  <div class="doccard-groups" v-if="groups.length">
    <Tags 
      class="doccard-groups-tabs"
      @changed="setTab"
      v-if="groups.length > 1"
      :value="this.curGroupId"
      :items="tabs"
    />
    <div class="doccard-tabs-content">
        <DocCardGroup
          v-for="lg in loadedGroups"
          v-show="curGroupId === lg.id"
          :key="lg.id"
          :ref="'grp'+lg.id"
          :group="lg.group"
          :documentInfo="documentInfo"
          :cardModel="cardModel"
        />
    </div>
  </div>
</template>
<script>
  import LoaderBlock from '@/components/ui/LoaderBlock.vue'
  import Tags from '@/components/ui/Tags.vue'
  import {backend} from '@/main'
  import {shallowRef} from 'vue'
  import DocCardGroup from './DocCardGroup.vue'
  //import {Splitpanes, Pane} from 'splitpanes'

  //items: Array,
  //itemId:
  //value: [String, Number]

  export default {
    name: 'DocCardTabs',
    components: {
      LoaderBlock,
      Tags,
      DocCardGroup
    },
    props: {
      documentInfo: Object,
      groups: Object,
      cardModel: Object,
      markedFields: {
        type: Array,
        default: null
      }
    },
    data() { return {
      loadedGroups: [],
      curGroupId: null
    }},

    computed: {
      tabs() {
        return this.groups.map(g=>({
          "id": g.VIEW_GROUP_ID,
          "label": g.GROUP_NAME,
          "group": g
        }))
      }
    },
    
    mounted() {
      if (!!this.tabs.length) {
        this.setTab(this.tabs[0])
      }
    },

    methods: {
      setTab(tabItm) {
        this.curGroupId = tabItm.id
        let curLG = this.loadedGroups.find( lg => lg.id === tabItm.id )
        if (curLG) return
        curLG = {
          id: tabItm.id,
          group: tabItm.group
        }
        this.loadedGroups.push(curLG)
      }
    }
  }
</script>
<style lang="sass">
.doccard-groups
  flex: 1
  display: flex
  flex-direction: column
  padding: 10px
.doccard-groups-tabs
  justify-content: safe center
  margin-bottom: 10px
.doccard-tabs-content
  flex: 1
</style>
