<template>
  <div class="pd-video-conf-wrap">

    <div class="pd-video-conf-wrap__body flex column center middle" style="justify-content: center">
      <h2 class="pd-video-conf-title" v-text="'Вы успешно записались'"></h2>
      <strong style="margin-top: 1rem">
        <span v-text="formatDate"></span>
        в
        <span v-text="formatTime"></span>
      </strong>
    </div>

    <button class="pd-video-conf-wrap__footer pd-button not-pill success"
            @click="$emit('close')"
            v-text="'Закрыть'"></button>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'NewVisitSuccess',

  props: {
    bookSlot:  { type: Object, default: null }
  },

  computed: {
    formatDate () {
      return dayjs(this.bookSlot.time).format('D MMMM YYYY[,] dddd')
    },
    formatTime () {
      return dayjs(this.bookSlot.time).format('HH:mm')
    }
  },

  methods: {
  }
}
</script>
