<template>
<teleport to="#vue_app">
    <div v-if="outerVisible" class="pd-modal-container" :style="{'z-index':zindex}">
      <!--div class="pd-modal-background" @click="close()"></div-->
      <div 
        @mousedown="outsideDown($event)" @mouseup="outsideUp($event)"
        ref="outside" class="pd-modal-outside" :class="{ 'full-height':fullHeight }">
        <transition name="fade" appear @after-enter="$emit('opened')" @after-leave="closed">
          <div v-if="visible" class="pd-modal" :class="modalClass" :style="{ 'width': width+'px' }">
            <div class="pd-modal-header">
              <span class="flex-1">{{title}}</span>
              <a @click.prevent="close()">
                <Icon name="Close" :size="18"/>
              </a>
            </div>
            <div class="pd-modal-body">
              <slot></slot>
            </div>          
          </div>
        </transition>
      </div>
      
    </div>
</teleport>
</template>

<script>
import Icon from '@/components/ui/Icon.vue'
import { backend } from '@/main'

export default {
  name: 'Modal',

  components: {
    Icon
  },

  props: {
    autoOpen: Boolean,
    fullHeight: Boolean,
    width: {
      type: Number,
      default: 400
    },
    title: String,
    modalClass: String
  },

  emits: ['opened','closing','closed','select'],

  data: ()=>({
    outerVisible: false,
    visible: false,
    outsideClick: false,
    zindex: 1000
  }),
  
  async mounted() {
    if (this.autoOpen) this.open()
  },

  methods: {
    open() {
      this.outerVisible = true
      this.visible = true
      this.zindex = backend.modals.getZIndex()
      backend.modals.addModal(this)
    },
    
    close() {
      this.$emit("closing")
      this.visible = false
    },

    outsideDown(ev) {
      if (ev.target !== this.$refs.outside) return
      this.outsideClick = true
    },

    outsideUp(ev) {
      //console.log(ev)
      if (!this.outsideClick) return
      this.outsideClick = false
      if (ev.target !== this.$refs.outside) return
      this.close()
    },

    async closed() {
      backend.modals.delModal(this)
      await this.$nextTick()
      this.outerVisible = false
      this.$emit("closed")
    }
  }
}
</script>
<style lang="sass">
@import "../sass/variables"
@import "../sass/mixins"

.pd-modal-container
  position: fixed
  top: 0
  bottom: 0
  z-index: 1200
  left: 0
  right: 0

//.pd-modal-background
//  @include cover

.pd-modal-outside
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  padding: $global-margin/2
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: rgba(0, 0, 0, .2)

  .pd-modal
    //width: 400px
    max-width: 100%
    max-height: 100%
    flex: 0 1
    background-color: var(--color-background)
    border-radius: $border-radius
    box-shadow: var(--shadow-large)
    display: flex
    flex-direction: column
    &-header
      border-radius: $border-radius $border-radius 0 0
      padding: $global-margin/2
      display: flex
      align-items: center
      background-color: var(--color-background-muted-light)
      a
        flex: none
        display: flex
        align-items: center
        color: var(--color-danger)
        justify-self: flex-end
    &-body
      flex: 0 1 auto
      display: flex
      flex-direction: column
      overflow-y: auto
    &-footer
      border-top: 1px solid var(--border-color)
  
  &.full-height
    //align-items: stretch
    .pd-modal
      flex: 1 1 auto
      &-body
        flex: 1 1 auto

</style>