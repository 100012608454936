<template>
  <div class="pd-sidebar">

    <!--logo-->
    <div class="pd-sidebar-logo">
      <Logo :light="true" :width="140" />
    </div>

    <div class="pd-sidebar-user">
      <SideBarUser @close="$emit('close')" />
    </div>

    <!--nav-->
    <div class="pd-sidebar-nav" @click="navClick">

      <ul class="pd-sidebar-nav-list">

        <li v-for="(navItem, index) in cabinetMenu" :key="index" :class="{active: $route.name === navItem.NAME}"
            class="pd-sidebar-nav-list-item" @click.stop="navItemClick(navItem)">
          <!--"navItem.path"-->
          <div>
            <Icon :name="navItem.ICON" style="width:24px" />
            <span class="pd-sidebar-nav-list-item--label" v-text="navItem.LABEL"></span>
            <span class="pd-sidebar-nav-list-item--icon"></span>
          </div>
        </li>

        <li class="pd-sidebar-nav-list-item" @click.stop="logout">
          <a>
            <Icon name="SignOut" style="width:24px" />
            <span class="pd-sidebar-nav-list-item--label"
                  v-text="'Выход'"></span>
            <span class="pd-sidebar-nav-list-item--icon"></span>
          </a>
        </li>
      </ul>

    </div>

    <!--footer-->
    <div class="pd-sidebar-footer" @click="showBuildInfo" v-text="version"></div>

  </div>
</template>

<script>
import {backend, carabi} from '@/main'
import Logo from '@/components/Logo.vue'
import SideBarUser from './SideBarUser.vue'
import Icon from '@/components/ui/Icon.vue'

import buildInfo from '@/buildinfo.json'
//const buildInfo = {"date":"2023-08-19T23:51:21+03:00","branch":"dev","commit":"eedc8dd9501a355335fca8b19e1672d76a5cf8b6","count":"472"}

export default {
  name:       'SideBar',
  components: {
    Logo,
    SideBarUser,
    Icon

    // IconFiles:    () => import(/* webpackChunkName: "IconFiles" */ '@/components/ui/icons-leg/IconFiles'),
    // IconChat:     () => import(/* webpackChunkName: "IconChat" */ '@/components/ui/icons-leg/IconChat'),
    // IconCalendar: () => import(/* webpackChunkName: "IconCalendar" */ '@/components/ui/icons-leg/IconCalendar'),
    // IconBilling:  () => import(/* webpackChunkName: "IconBilling" */ '@/components/ui/icons-leg/IconBilling'),
    // IconMedCard:  () => import(/* webpackChunkName: "IconMedCard" */ '@/components/ui/icons-leg/IconMedCard'),
    // IconContacts: () => import(/* webpackChunkName: "IconContacts" */ '@/components/ui/icons-leg/IconContacts'),
    // IconSettings: () => import(/* webpackChunkName: "IconSettings" */ '@/components/ui/icons-leg/IconSettings'),
    // IconSignOut:  () => import(/* webpackChunkName: "IconSignOut" */ '@/components/ui/icons-leg/IconSignOut'),
    // IconNotify:   () => import(/* webpackChunkName: "IconNotify" */ '@/components/ui/icons-leg/IconNotify')
  
  },

  props: {
    data: {
      type:    Object,
      default: () => {}
    }
  },

  data() {
    return {
      cabinetMenu: []
    }
  },

  computed: {

    version() {
      const title = backend.config.title || "MedDoc"
      const version = buildInfo.count
      return `© ${ title }. v2.${ version }`
    },

    userType() {
      if ( this.$store.getters.userType ) {
        if ( this.$store.getters.userType === 1 ) return 'doctor'
        if ( this.$store.getters.userType === 2 ) return 'patient'
      }
    },

    userContactLabel() {
      if ( this.$store.getters.userType ) {
        if ( this.$store.getters.userType === 1 ) return 'Пациенты'
        if ( this.$store.getters.userType === 2 ) return 'Врачи'
      }
    },

    userFileLabel() {
      if ( this.$store.getters.userType ) {
        if ( this.$store.getters.userType === 1 ) return 'Файлы'
        if ( this.$store.getters.userType === 2 ) return 'Медицинская карта'
      }
    }
  },

  async mounted() {
    await this.loadCabinetMenu()
  },

  methods: {

    async loadCabinetMenu() {
      let res = await carabi.select( 'MD_GET_CABINET_MENU', {}, 100 )
      for (let m of res) {
        m.ICON = m.ICON.replace('Icon','')
      }
      this.cabinetMenu = res
    },

    navClick() {
      this.navItemClick({ NAME: 'Chats' })
    },

    navItemClick( navItem ) {
      console.log("navItemClick",navItem);
      try {
        this.$router.push( { name: navItem.NAME } )
        this.$emit("close")
      } catch(e) {
        console.error(e)
      }
    },

    getLabel( item ) {
      if ( !item ) return
      if ( item.name === 'Contacts' ) {
        return this.userContactLabel
      }
      //else if (item.name === 'Files') {
      //  return this.userFileLabel
      //}
      else {
        return item.meta.label
      }
    },

    //close () {this.$store.commit('sidebarOpenToggle', false)},

    async logout() {
      if (await backend.popups.confirm("Выйти из учетной записи?")) {
        await backend.logout()
        this.$router.push( { name: 'Home' } )        
      }
      //button:   { no: 'Отмена', yes: 'Выйти' },
    },

    showBuildInfo() {
      const versionText =
        'Версия ' + buildInfo.count + ' ' + buildInfo.branch + ' <br>' +
        'Сборка ' + buildInfo.commit + ' ' + ' <br>' +
        'От ' + buildInfo.date
      this.$store.commit( 'addNotify', { content: versionText, delay: 10, type: 'info' } )
    }
  }
}
</script>