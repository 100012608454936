<template>
  <notify />
  <template v-if="loaded">
    <Layout v-if="hasLayout"><router-view/></Layout>
    <router-view v-else />
  </template>
  <start-error v-else-if="error" :error="error" />
  <starting v-else />
  <offline v-show="offline" />
  <AttachViewModal />
  <ContactProfileModal />
  <Popups />
</template>
<script>
import {backend} from './main.js'
import {processError} from '@/utils/errors'
import Layout from '@/layout'
import Starting from '@/routes/starting.vue'
import StartError from '@/routes/start-error.vue'
import Offline from '@/components/offline'
import Notify from './components/ui/Notify'
import Popups from '@/modals/Popups'

import AttachViewModal from '@/modals/AttachViewModal'
import ContactProfileModal from '@/modals/ContactProfileModal'

//import {timeout} from '@/utils/async-utils'

export default {
  components: {
    Layout,
    Starting,
    StartError,
    Offline,
    Notify,
    Popups,
    AttachViewModal,
    ContactProfileModal
  },
  data:()=>({
    loaded: false,
    error: null
  }),
 
  computed: {
    hasLayout() {
      return this.$route?.meta?.layout || false
    },
    offline() {
      return (
        backend.authorised.value && 
        backend.eventer.status.value !== "ready"
      )
    }
  },

  watch: {
    $route (to, from) {
      backend.modals.closeAll()
    },
  },
  
  async created() {
    try {
      await backend.init()
      let redirect = backend.checkRoute(this.$route)
      console.log('init redirect',redirect,this.$route)
      if (redirect) {
        await this.$router.push(redirect)
      } else {
        document.title = this.$route?.meta?.title || this.$route.name
      }
      await this.$nextTick()
      this.loaded = true
    } catch(e) {
      console.error(e)
      this.error = e
    }
  },
  methods: {
  }
}
</script>
<style lang="sass">
  @import "./sass/index.sass"
</style>