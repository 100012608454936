<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       class="pd-main-icon"
       width="596.67203"
       height="728.99791"
       viewBox="0 0 596.67203 728.99791">
    <path d="M308.27265,638.50341a22.04855,22.04855,0,0,0-.45019,31.02539L443.20283,807.78661a21.99981,21.99981,0,0,0,31.34375.30274L891.8581,384.62011a22.00167,22.00167,0,0,0-3.5166-34.02441L733.10029,247.6665a21.87127,21.87127,0,0,0-27.37793,2.69482Z"
          transform="translate(-301.66398 -85.50104)"
          fill="#3f3d56"/>
    <path d="M867.39484,368.24761l-17.30761-11.03509a16.49049,16.49049,0,0,1-23.734,21.65173l-112-77a16.48044,16.48044,0,0,1,4.35736-29.30127l-8.917-5.6853a6,6,0,0,0-7.49359.84192L325.52393,637.98846a6,6,0,0,0,.15186,8.58276L462.51167,779.90228a6.00006,6.00006,0,0,0,8.35174-.116L868.40485,377.55639A6,6,0,0,0,867.39484,368.24761Z"
          transform="translate(-301.66398 -85.50104)"
          fill="#fff"/>
    <polygon points="130.873 470.728 246.456 579.706 521.379 308.086 381.853 217.271 130.873 470.728"
             fill="#e6e6e6"/>
    <path d="M812.35712,460.62109a4.09728,4.09728,0,0,0,5.65723,0l24.042-24.04151a4.00621,4.00621,0,0,0,0-5.65674,4.09726,4.09726,0,0,0-5.65722,0l-24.042,24.04151a4.00623,4.00623,0,0,0,0,5.65674Z"
          transform="translate(-301.66398 -85.50104)"
          fill="#3f3d56"/>
    <path d="M771.85712,501.12158a4.00682,4.00682,0,0,0,5.65723,0l24.042-24.042a4.00026,4.00026,0,1,0-5.65722-5.65722l-24.042,24.042A4.00593,4.00593,0,0,0,771.85712,501.12158Z"
          transform="translate(-301.66398 -85.50104)"
          fill="#3f3d56"/>
    <circle cx="133.54275"
            cy="564.27092"
            r="27"
            fill="#69a50a"/>
    <ellipse cx="286.04275"
             cy="434.77092"
             rx="74.5"
             ry="40.5"
             fill="#fff"/>
    <path d="M491.67133,169.159l-.89061,8.08733-.524,4.78559-2.57814,23.40781s-1.96538,3.64988-5.48023,8.767c-5.84564,8.50466-16.00436,21.06005-28.61066,27.66774-20.20063,10.58484-28.16743-65.436-28.16743-65.436s-4.36463-29.47459,6.35918-30.55633,7.03527,11.614,7.85034,32.502,14.82181,38.557,14.82181,38.557,14.90823-20.68516,21.45893-34.56415c3.412-7.23058,7.54992-10.51622,10.725-11.99634l.0095-.00392a13.14507,13.14507,0,0,1,3.2987-1.07319A7.81044,7.81044,0,0,1,491.67133,169.159Z"
          transform="translate(-301.66398 -85.50104)"
          fill="#ffb8b8"/>
    <path d="M458.49219,202.42451l32.88113,19.11563,17.90448-31.90332-4.04411-14.38892h0a24.72872,24.72872,0,0,0-32.31311,1.584Z"
          transform="translate(-301.66398 -85.50104)"
          fill="#69a50a"/>
    <path d="M470.52539,88.46812h0c-19.03439,7.86854-27.38586,31.37162-18.6535,52.49564L462.96643,167.802l8.25422-3.41217.66811-11.91923,2.91591,10.43765,53.055-21.93213.60735-10.83567,2.65089,9.48876,5.97344-2.46933L528.38009,116.087C518.33137,91.77894,492.429,79.41349,470.52539,88.46812Z"
          transform="translate(-301.66398 -85.50104)"
          fill="#2f2e41"/>
    <path d="M489.44765,225.93336l3.34673,24.30985,52.63884-23.99431-8.44762-17.73282S492.57653,214.586,489.44765,225.93336Z"
          transform="translate(-301.66398 -85.50104)"
          fill="#a0616a"/>
    <polygon points="172.549 249.504 205.497 280.567 213.435 253.827 189.035 232.635 172.549 249.504"
             fill="#ffb8b8"/>
    <polygon points="273.458 372.003 293.508 415.101 314.498 406.425 293.493 363.721 273.458 372.003"
             fill="#ffb8b8"/>
    <path d="M475.77519,287.4404l22.55568,27.54017L575.74521,461.715s14.9345-1.70531,22.50316-10.41954l-6.76859-21.7782-45.87766-121.7897s14.18325-17.03414,6.75357-29.60227-19.69353-28.72334-19.69353-28.72334Z"
          transform="translate(-301.66398 -85.50104)"
          fill="#2f2e41"/>
    <path d="M593.69592,488.92457l-5.72437,2.36637s-9.12,23.87789-14.05558,28.15241-27.80686,32.71985-2.77209,25.72217,35.17272-25.71087,35.17272-25.71087,9.60448,1.61518,18.52159-6.53948-1.54-17.237-1.54-17.237-12.54073-15.24675-12.68212-15.02677S608.29982,491.82432,593.69592,488.92457Z"
          transform="translate(-301.66398 -85.50104)"
          fill="#2f2e41"/>
    <circle cx="479.31731"
            cy="128.46479"
            r="23.74453"
            transform="translate(-314.38514 107.35656) rotate(-22.45948)"
            fill="#ffb8b8"/>
    <path d="M523.58738,143.67725l-6.61545,12.58759-.88939,1.68582,3.77871,11.84313-37.20849,15.38144s3.25747-6.49642,6.08929-12.99564c.052-.14436.10961-.30222.18075-.45455.38284-.88435.71864-1.69347,1.02089-2.42161.15834-.40057.315-.77816.451-1.12482.2216-.57194.44159-1.12088.66146-1.6698a.03594.03594,0,0,0,.00164-.023,40.29439,40.29439,0,0,0,1.41467-4.36062l.00557-.01342a11.99934,11.99934,0,0,0,.24874-1.20875c.89021-5.95355-9.73219-12.73337-9.73219-12.73337l16.65106-19.17142c5.00987,10.03835,16.6141,13.32914,21.5985,14.30721C522.70059,143.5858,523.58738,143.67725,523.58738,143.67725Z"
          transform="translate(-301.66398 -85.50104)"
          fill="#ffb8b8"/>
    <path d="M641.36543,282.6627c-12.73337,9.73218-25.51933-18.49532-25.51933-18.49532l-35.681-30.99024A97.753,97.753,0,0,1,560.91623,210.449l-16.15917-26.37043-.68991,2.1954.00393.0095-1.94709,2.23484-24.76236-17.69118-6.93956-4.951-3.15518-7.63252,11.55018-12.5944,5.72437-2.36637s.11614-.07037.32766-.18017c2.51888-1.3652,19.18057-9.59344,26.97209,6.76848,3.059,6.42118,5.27714,11.87164,6.86239,16.2124a136.33023,136.33023,0,0,0,17.84565,33.74455c2.72305,3.72627,5.31264,6.86954,7.21177,8.34239,6.577,5.10088,41.62573,52.05286,41.62573,52.05286S654.09879,272.93046,641.36543,282.6627Z"
          transform="translate(-301.66398 -85.50104)"
          fill="#ffb8b8"/>
    <path d="M492.47242,162.12524c.00523.1207-.09217,1.1552-2.07776,6.0534A49.82741,49.82741,0,0,0,492.47242,162.12524Z"
          transform="translate(-301.66398 -85.50104)"
          fill="#d0cde1"/>
    <path d="M544.47724,184.75274l-.40615,1.53072-.29406,31.48842s-12.47562,22.57229-26.62133,26.18572c-8.04219,1.80755-23.11894-6.11383-24.46724,2.01794L487.08121,220.209l-4.883-6.00222c-.56987-.70283-1.21325-1.47576-1.92311-2.35525-6.80617-8.35745-.64982-23.19052-.64982-23.19052l7.01013-18.28039.0095-.00393a13.145,13.145,0,0,1,3.2987-1.07318c-.30226.72814-.63806,1.53726-1.0209,2.42161-.07113.15233-.12874.31019-.18074.45455-2.112,5.2409.21649,5.49606,2.03866,5.0667a9.09258,9.09258,0,0,0,1.74326-.62007c4.215-1.0722,11.48217-8.16491,16.66758-13.72681,3.53016-3.78289,6.10224-6.85692,6.10224-6.85692l5.17381-11.0756.77655-1.66157c1.45648.28043,2.34327.37188,2.34327.37188l.95408-.3944s.11614-.07037.32765-.18017L521.8575,148.122a17.47565,17.47565,0,0,0-.71167,16.67233h0Z"
          transform="translate(-301.66398 -85.50104)"
          fill="#69a50a"/>
    <path d="M503.72106,233.43813l-11.69657,11.21281s-87.84542,2.00881-89.43426,30.59318S476.18461,339.775,476.18461,339.775l18.55918-19.96021-21.3725-24.678s74.66117,23.87418,86.91-11.35105-18.07312-65.33837-18.07312-65.33837S523.23428,213.08354,503.72106,233.43813Z"
          transform="translate(-301.66398 -85.50104)"
          fill="#2f2e41"/>
    <path d="M506.63952,353.99578l-5.8258,10.228s20.25316,27.37487,19.363,33.32838-1.09306,21.67677,9.00726,16.38438,14.84437-26.24429,13.432-32.363-1.833-26.0527-1.833-26.0527.40571-31.44655-8.57528-28.85107-22.92108,7.61049-22.92108,7.61049Z"
          transform="translate(-301.66398 -85.50104)"
          fill="#2f2e41"/>
    <path d="M484.67863,94.954a19.55855,19.55855,0,0,0-17.27042-1.38229l-.67665.27971c-13.04624,5.39312-18.74206,21.57036-12.72207,36.133l0,0,4.3714-1.80707-1.51542-5.66551,3.25651,4.94577L486.04878,116.74l.29283-5.44414,1.33627,4.7707,5.10053-2.10849q10.89951,16.25435,4.39939,39.7015l8.68867-3.59177.58561-10.88826,2.67259,9.54137,16.56267-6.84676-5.38671-22.25952C513.82641,103.953,499.23651,94.434,484.67863,94.954Z"
          transform="translate(-301.66398 -85.50104)"
          fill="#2f2e41"/>
    <path d="M582.74089,177.37542l-50.13234,20.724-16.07894-38.89578,6.59153-15.88159h0a28.44918,28.44918,0,0,1,36.69281,6.23812Z"
          transform="translate(-301.66398 -85.50104)"
          fill="#69a50a"/>
  </svg>
</template>

<script>
export default {
  name: 'IconOffLine'

}
</script>


