<template>
  <div class="pd-notify">
    <transition name="notify"
                mode="in-out"
                appear>

      <div class="pd-notify-message"
           :class="message.type"
           v-if="messagesCount > 0">

        <!--progress-->
        <div class="pd-notify-message-progress" v-if="message.delay">
          <div ref="progress" class="pd-notify-message-progress__line"></div>
        </div>

        <!--content-->
        <div class="pd-notify-message__content">

          <!--count-->
          <div class="pd-notify-message__count"
               v-if="messagesCount > 1"
               v-text="messagesCount"></div>

          <span v-html="message.content">
          </span>

        </div>

        <!--Close-->
        <div class="ppd-notify-message__close pd-link inverse"
             role="button"
             @click="close">
          <Icon name="Close" :size="18"/>
        </div>

      </div>
    </transition>

  </div>
</template>

<script>
import {backend} from '@/main'
import sleep from '@/utils/sleep'

export default {
  name: 'Notify',
  components: {
  },

  data () {
    return {
    }
  },

  watch: {
    message: {
      deep: true,
      handler (message) {
        if (this.closeTimer !== null) {
          clearTimeout(this.closeTimer)
        }
        if (!message) return
        this.startTimer()
      }
    }
  },

  computed: {
    message () { return backend.notifications.lastMessage.value },
    messagesCount () { return backend.notifications.messagesCount.value },
  },

  created() {
    this.closeTimer = null
  },

  methods: {

    async startTimer() {
      this.closeTimer = setTimeout(() => { this.close() }, this.message.delay)
      //if (!this.$refs.progress)
      await sleep(10)
      this.$refs.progress.style.transitionDuration = "0s"
      this.$refs.progress.style.height = "100%"
      await sleep(10)
      const delayTime = (this.message.delay/1000)+"s"
      this.$refs.progress.style.transitionDuration = delayTime
      await sleep(10)
      this.$refs.progress.style.height = "0%"
    },

    close () {
      backend.notifications.closeLast()
    }
  }
}
</script>
