export default class CardApi {

  constructor() {
    this.queries = null
  }

  async getDocument( documentId ) {
    //info = await this.queries.selectRow('CARD_DOCUMENT_INFO', {DOCUMENT_ID: documentId});
    let rawGroups = await this.queries.select( 'CRM_CARD_VIEW_GROUPS', { document_id: documentId }, 100 )
    let proms = []
    rawGroups.forEach( ( grp ) => {
      proms.push( this.loadGroup( documentId, grp ) )
    } )
    return await Promise.all( proms )
  }

  async loadGroup( documentId, grp ) {
    let result = {
      id:     grp.VIEW_GROUP_ID,
      plugin: grp.GROUP_PLUGIN_NAME,
      style:  grp.GROUP_STYLE,
      type:   grp.GROUP_TYPE,
      title:  grp.GROUP_NAME
    }
    let proms  = []

    let rawFields = await this.queries.select( 'CRM_CARD_VIEW_PROP_VALUES', {
      VIEW_GROUP_ID: grp.VIEW_GROUP_ID, //number
      DOCUMENT_ID:   documentId //number
    }, 1000 )

    rawFields.forEach( ( fld ) => {
      proms.push( this.loadField( documentId, fld ) )
    } )

    result.fields = await Promise.all( proms )
    console.log( 'loadGroup result', result )
    return result
  }

  async loadField( documentId, fld ) {
    let type   = this.getType( fld )
    let result = {
      //raw: fld,
      type,
      prop_id:    fld.DOCPROP_ID,
      prop_name:  fld.DOCPROP_DESCR,
      title:      fld.DOCPROP_NAME,
      required:   !!fld.DOCPROP_NOTNULL,
      readonly:   fld.DOCPROP_READONY,
      multipile:  !!fld.DOCPROP_MULTI,
      value:      fld.DOCPROP_VALUE,
      display:    fld.DISPLAY,
      documentId: fld.DOCUMENT_ID
    }
    if ( result.value ) {
      switch ( type ) {
        case 'diaryTpl':
          result.value = parseFloat( result.value )
          break
        case 'number':
          result.value = parseFloat( result.value )
          break
        case 'special':
          result.value = parseFloat( result.value )
          break
        case 'bankCard':
          result.value = parseFloat( result.value )
          break
        case 'mkb':
          result.value = parseFloat( result.value )
          break
        case 'link':
          result.value = parseFloat( result.value )
          break
        case 'boolean':
          result.value = !!result.value !== '0'
          break
        case 'date':
          result.value = result.value
          break
        case 'address':
          result.documentId = result.value
          let addr          = await this.queries.query( 'ADDRESS_GET_ADDRESS_JSON', {
            DOC_ID: result.documentId //varchar
          } )
          try {
            result.value = ( addr.RESULT ) ? JSON.parse( addr.RESULT ) : null
          }
          catch ( e ) {
            console.error( 'Address parse error', addr.RESULT )
            result.value = null
          }
          break
      }
    }
    if ( type === 'dictionary' ) {
      let rawOptions = await this.queries.select( 'CARD_POPUP_VOCAB', {
        VOCAB_NAME: fld.DOCPROP_OBJECT
      }, 10000 )
      result.options = rawOptions.map( ( opt ) => ( { id: opt.CODE, title: opt.VALUE } ) )
    }
    return result
  }

  flatFields( groups ) {
    let result = []
    groups.forEach( ( grp ) => {
      grp.fields.forEach( ( fld ) => {
        result.push( fld )
      } )
    } )
    return result
  }

  async setDocument( documentId, fields, commit = true ) {
    alert("Old setDocument")
    let proms = []
    fields.forEach( ( fld ) => {
      let val = fld.value
      //if (!val) return

      //convert values
      switch ( fld.type ) {
        case 'date':
          //TODO: date convert
          break
        case 'boolean':
          val = val ? '1' : '0'
          break
      }

      //switch setters
      switch ( fld.type ) {
        case 'fio':
          //TODO: FIO setter
          break
        case 'address':
          proms.push(
            this.queries.query( 'ADDRESS_UPDATE_ADDRESS_JSON', {
              DOC_ID:      fld.documentId, //number
              ADRESS_JSON: JSON.stringify( val ) //clob
            } )
          )
          break
        default:
          proms.push(
            this.queries.query( 'TRANSACTION_SET_VALUE', {
              CARD_ID:     documentId, //number
              DOCUMENT_ID: fld.documentId, //number
              PROP_NAME:   fld.prop_name, //varchar
              VALUE:       val //clob
            } )
          )
      }
    } )
    await Promise.all( proms )

    if ( commit ) {
      let result = await this.queries.query( 'TRANSACTION_COMMIT', {
        CARD_ID: documentId //number
      } )
      if ( result.ERRMESS ) {
        throw result.ERRMESS
      }
      return result
    }
    else {
      return proms
    }

  }

  async runAction( documentId, actionId ) {
    let runResult = await this.queries.query( 'CRM_SET_DOCS_PARAM_ACTION3', {
      'DOCLIST':      documentId, //varchar
      'BP_ACTION_ID': actionId, //varchar
      'PARAM_LIST':   '' //varchar
    } )
    if ( runResult.errmess ) {
      throw runResult.errmess
    }
  }

  getType( field ) {

    //if (field.DOCPROP_DESCR.indexOf('BANK_NO') !== -1) {return 'bankCard'}
    if ( field.DOCPROP_DESCR.indexOf( 'DIARY-REF-DIARY' ) !== -1 ) {return 'diaryTpl'}
    if ( field.DOCPROP_DESCR.indexOf( 'MED_ILL-REF-MKB' ) !== -1 ) {return 'mkb'}
    if ( field.DOCPROP_DESCR.indexOf( 'TIME_REMIND' ) !== -1 ) {return 'time'}
    if ( field.DOCPROP_DESCR.indexOf( 'CLINICAL_EPISODE-REF-MKB' ) !== -1 ) {return 'mkb'}
    if ( field.DOCPROP_DESCR.indexOf( 'CLINICAL_EPISODE-BREF-MED_ILL' ) !== -1 ) {return 'link'}

    if ( field.DOCPROP_KIND === 1 ) {

      if ( field.DOCPROP_DESCR.indexOf( 'EMAIL' ) !== -1 ) {return 'email'}
      if ( field.DOCPROP_DESCR.indexOf( 'MOBILE_PHONE' ) !== -1 ) {return 'text'}
      if ( [ '2', '11' ].indexOf( field.DOCPROP_OBJECT ) !== -1 ) {return 'number'}
      if ( field.DOCPROP_OBJECT === '4' ) {return ( field.DOCPROP_SQL !== '0' ) ? 'textarea' : 'text'}
      if ( field.DOCPROP_OBJECT === '5' ) {return 'boolean'}
      if ( field.DOCPROP_OBJECT === '32' ) {return 'date'}
    }
    if ( field.DOCPROP_KIND === 9 ) {
      if ( field.DOCPROP_DESCR.indexOf( 'DOC_CARD-REF-SPECIAL_DOC' ) !== -1 ) {return 'special'}
      if ( field.DOCPROP_DESCR.indexOf( 'PERSON' ) !== -1 ) {return 'fio'}
      if ( field.DOCPROP_DESCR.indexOf( 'ADRES' ) !== -1 ) {return 'address'}
    }
    if ( field.DOCPROP_KIND === 10 ) {return 'dictionary'}
    if ( field.DOCPROP_KIND === 16 ) {return 'media'}
    return null
  }
}