import { reactive } from "vue";

export default class ContactView {
  constructor() {
    this.data = reactive({
      userId: null
    })
  }

  open(userId) {
    this.data.userId = userId
  }
}