import { backend } from "@/main"
import { UserError } from "@/utils/errors"
import { reactive, watch } from "vue"

//TODO: changed на событие сохранения, а не редактирования
export default class CardModel {
  constructor(config = {}) {
    this.config = {
      cardId: null,
      readOnly: false,
      autoCommit: false,
      ...config
    }
    this.info = null
    this.savePromises = []
    this.editingGroups = []
    this.data = reactive({
      committing: false,
      changed: false,
      saving: false
    })
  }

  async reload() {
    this.info = await backend.api.queries.selectRow('CARD_DOCUMENT_INFO2', {DOCUMENT_ID: this.config.cardId})
    this.info.DOCUMENT_ID = this.config.cardId
  }

  addEditing($grp) {
    this.data.changed = true
    let edg = this.editingGroups
    let idx = edg.findIndex(g => g === $grp)
    if (idx === -1) {
      edg.push($grp)
      this.editingChanged()
    }
  }

  delEditing($grp) {
    let edg = this.editingGroups
    let idx = edg.findIndex(g => g === $grp)
    if (idx !== -1) {
      edg.splice(idx,1)
      this.editingChanged()
    }    
  }

  editingChanged() {
    if (!!this.editingGroups.length) return
    //Все редакторы закрыты
    if (this.data.saving) {
      this.data.saving = false
      for (let p of this.savePromises) {
        p[0]()
      }
      this.savePromises = []
    }
    //Автокоммит
    if (this.config.autoCommit && this.data.changed) {
      this.commit()
    }
  }

  /**
   * 
   * @returns Сохранить все изменения в картоке
   */
  async saveGroups() {
    if (this.editingGroups.length === 0) return
    if (!this.data.saving) {
      this.data.saving = true
      for (let g of this.editingGroups) {
        //TODO: Catch on save
        console.log("saveGroup",g.save,g)
        g.save()
      }
    }
    return new Promise((resolve,reject) => {
      this.savePromises.push([resolve,reject])
    })
  }

  async commit(rollback = false) {
    if (this.data.committing) return
    try {

      this.data.committing = true
      await this.saveGroups()
      
      if (!rollback) {
        let result = await backend.api.queries.query('TRANSACTION_COMMIT', {
          CARD_ID: this.config.cardId //number
        })
        if ( result.ERRMESS ) {
          throw new UserError(result.ERRMESS)
        }
      } else {
        await this.carabi.query("TRANSACTION_ROLLBACK", { CARD_ID : this.config.cardId })
      }
      
      this.data.changed = false

    } finally {
      this.data.committing = false

    }
  }

  async rollback() {
    await this.commit(false)
  }

}
