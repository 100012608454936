import { ServerError, UserError } from "@/utils/errors"
export default class {

    constructor(subSystems=[]) {
        this.subSystems = subSystems
        this.defaultProject = null
        this.project = null
        this.token = null
        this.address = null
    }

    async init() {
        await Promise.all(this.subSystems.map( sn => this[sn].init() ))
    }

    setToken(newToken,newProject = null) {
        newProject = newProject || this.project
        for (const sn of this.subSystems) {
          this.subSystems[sn].setToken(newToken,newProject)
        }
        this.token = newToken || null
        this.project = newProject
    }

    setAddress(newAddress, defaultProject) {
        if (!newAddress) throw new Error("No Address")
        if (newAddress.slice(-1) != "/") newAddress += "/"
        for (const sn of this.subSystems) {
          this[sn].setAddress(newAddress)
        }
        this.address = newAddress || null
        this.defaultProject = defaultProject || null
    }

    getParams(obj) {
        var str = "";
        for (var key in obj) {
            if (str != "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(obj[key]);
        }
        return str? "?"+str: ""
    }

    /**
     * 
     * @param {string} method 
     * @param {string} url 
     * @param {object} params 
     * @returns {object}
     */
    async serverHttp (method, url, params=null, options = null) {
        if (!options) options = {}
        let curProject = options.project || this.project || this.defaultProject
        if (!curProject) throw new Error("API Project not set")
        let request = {
            method,
            headers: {}
        }
        if (this.token) {
            request.headers['authorization'] = 'Bearer '+this.token
        }
        if (params) {
            request.headers['Content-Type'] = 'application/json'
            request.body = JSON.stringify(params)
        }
        console.log('serverHttp',this.token,request.headers)
        let fetchUrl = this.address + curProject +'/'+url
        console.log('fetch url',fetchUrl)
        let response = await fetch(fetchUrl, request);
        if (method === 'HEADER') {
            return response.status
        }
        if (response.status === 204) {
            //no content
            return
        }
        let result = await response.text()
        console.log('result',result)
        try {
            result = JSON.parse(result)
        } catch (e) {
            throw new ServerError('Response parse error', "InvalidResponse", {response:result})
        }
        if (response.status !== 200) {
            let message = result.message? result.message: response.statusText
            if (["OracleUser"].includes(result.exception)) {
                throw new UserError(message)
            }
            throw new ServerError(message, result.exception, result.payload, {logId: result.logId})            
        }
        return result
    }

    //https://panel.delivery-hub.com/api/accounts/e38b78a8-47b4-476c-822a-3a05a2d59ef7/access
}