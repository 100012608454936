/**
 * Кеш, соединяющий асинхронные вызовы данных
 * Чтобы не заправшивалось дважды одно и тоже, если кеш еще не сформировался
 */
export default class AsyncCachedList {
  //TODO: TTL
  
      constructor() {
          this.idProperty = 'id' 
          this.items = []
      }
  
      /**
       * @param {*} id 
       * @returns {Promise<object>}
       */
      get(id) {
          return new Promise((resolve, reject) => {
            let item = this.items.find((itm)=> itm[this.idProperty] === id )
            if (!item) {
                item = {
                    id,
                    loaded: false,
                    promises: [[resolve, reject]],
                    data: null,
                    error: null
                }
                this.items.push(item)
                
                this.load(id).then((result)=>{
                    item.loaded = true
                    item.data = result
                    for (let p of item.promises) {
                        p[0](result)
                    }
                    item.promises = []
                }).catch((e)=>{
                    item.loaded = true
                    item.error = e
                    for (let p of item.promises) {
                        p[1](e)
                    }
                    item.promises = []
                })
  
            } else if (!item.loaded) {
                //Загрузка начата, но ответа еще нету
                item.promises.push([resolve, reject])

            } else {
                //Загрузка уже сделана и кеширована
                if (item.error) {
                    reject(item.error)
                } else {
                    resolve(item.data)
                }

            }
          })
      }
  
      remove(id) {
          let i = this.items.findIndex((itm)=> itm[this.idProperty] === id )
          if (i !== -1) this.items.splice(i, 1)
      }

      async reload(id) {
          this.remove(id)
          return this.get(id)
      }
  
      clear() {
          //Оставляем загружаемые сейчас данные (чтобы не ломались промисы)
          this.items = this.items.filter( itm => !itm.loaded )
      }
  
      /**
       * @param {*} id 
       * @returns {Promise<object>}
       */
      async load(id) {
          throw new Error("load not implemented")
      }
  
  }