<template>
  <img :src="logo" :width="width" />
</template>
<script>
import {backend} from '@/main'
export default {
  name: 'Logo',

  props: {
    width: {
      type:    Number,
      default: 200
    },
    light: {
      type:    Boolean,
      default: false
    }
  },

  data() {
    return {
      fill: [
        {
          light: false,
          color: '#ff6e5b'
        }, {
          light: true,
          color: '#ffffff'
        }
      ]
    }
  },

  computed: {
    logo() {
      return ( this.light )
          ? `/theme/logo/logo_full_contrast.svg`
          : `/theme/logo/logo_full.svg`
    },
    currentMode() {
      return this.fill.find( item => item.light === this.light ).color
    }
  }
}
</script>