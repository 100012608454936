import Api from './api/index.js'

export default class Document {

  constructor (api = null) {
    /** @type {Api}  */
    this.api = api
  }

  async listVocab(vocabName) {
    return await this.api.queries.select('CARD_POPUP_VOCAB', {
      VOCAB_NAME: vocabName
    }, 500)
  }

  async getValue(documentId, propName) {
    let rawValue = await this.api.queries.selectRow('CRM_GET_VALUE',{
      "DOCUMENT_ID" : documentId, //varchar
      "FIELD_NAME": propName //varchar
    })
    return rawValue.VALUE
  }

  async getValues(documentId, propNames) {
    let result = {}
    let fillResult = async (p)=>{
      result[p] = await this.getValue(documentId, p)
    }
    await Promise.all(propNames.map(p => fillResult(p)))
    return result
  }

  async getAllValues(documentId) {
    let rows = await this.api.queries.select('CRM_GET_DOC_VALUES',{
      "DOCUMENT_ID": documentId
    },100)
    //console.log('getAllValues',rows)
    //return rows
    let result = {}
    for (let row of rows) {
      if (row.DOCPROP_KIND === "DATE") {
        result[row.DOCPROP_NAME] = row.DOCPROP_VALUE_DATE
      } else if (row.DOCPROP_KIND === "NUMBER") {
        result[row.DOCPROP_NAME] = parseFloat(row.DOCPROP_VALUE)
      } else if (row.DOCPROP_OBJECT ===  "5") {
        result[row.DOCPROP_NAME] = row.DOCPROP_VALUE == "1"
      } else {
        result[row.DOCPROP_NAME] = row.DOCPROP_VALUE
      }
    }
    return result
  }  

  async listPropDocs(documentId, docPropId) {
    let rows = await this.api.queries.select("BR_CARABI_REFLIST_DATA4", {
      DOC_ID : documentId, //number
      DOCPROP_ID : docPropId //number
    }, 1000 )
    return rows
  }

  async listPropDocVals(documentId, docPropId) {
    let docs = await this.listPropDocs(documentId,docPropId)
    let fetchRow = async (row)=>{
      let newRow = await this.getAllValues(row.DOCUMENT_ID)
      newRow.DOCUMENT_ID = row.DOCUMENT_ID
      return newRow
    }    
    return await Promise.all(docs.map(d => fetchRow(d)))
  }

  async setValue(cardId, documentId, propName, newValue) {
    return this.api.queries.query( 'TRANSACTION_SET_VALUE', {
      CARD_ID:     cardId, //number
      DOCUMENT_ID: documentId, //number
      PROP_NAME:   propName, //varchar
      VALUE:       newValue //clob
    })
  }

  async setValues(cardId, documentId, newValues) {
    console.log('setValues', newValues)
    let proms = []
    for (let propName in newValues) {
      let value = newValues[propName]
      proms.push(
        this.api.queries.query('TRANSACTION_SET_VALUE', {
          CARD_ID:     cardId, //number
          DOCUMENT_ID: documentId, //number
          PROP_NAME:   propName, //varchar
          VALUE:       value //clob
        })
      )
    }
    await Promise.all( proms )    
  }  
  
  async addPropDoc(cardId, documentId, propId) {    
    let result = await this.api.queries.selectRow('TRANSACTION_CREATE_REF_DOC', {
      CARD_ID:     cardId, //number
      DOCUMENT_ID: documentId, //number
      REF_PROP_ID: propId //number
    })
    return result.DOCUMENT_ID
  }

  async delPropDoc(cardId, documentId, propId, propDocId) {
    return this.api.queries.query('TRANSACTION_DELETE_REF_DOCS', {
      CARD_ID:     cardId, //number
      DOCUMENT_ID: documentId, //number
      REF_PROP_ID: propId, //number
      DOC_LIST: propDocId
    })    
  }

  async commit(cardId) {
    await this.api.queries.query( 'TRANSACTION_COMMIT', {
      CARD_ID: cardId //number
    })
  }

}
