import { reactive } from "vue";

export default class Session {
  constructor() {
    this.data = null
  }

  load() {
    const session = localStorage.getItem('session')
    if (!session) this.data = null
    this.data = JSON.parse(session)
  }

  save() {
    const s = JSON.stringify(this.data)
    localStorage.setItem('session', s)
    //AppApi.setCarabiToken(s)
  }

  clear() {
    this.data = null
    localStorage.removeItem('session')
  }
}