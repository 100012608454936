<template>
<teleport v-if="outerVisible" to="#vue_app">
  <div class="pd-slide-panel-outer">
      <!--transition name="panel" mode="in-out" appear-->
      <transition name="panel" appear @after-enter="$emit('opened')" @after-leave="closed">
        <div v-if="visible" class="pd-slide-panel" :class="{large: large, full: full}">
          <!--HEADER-->
          <div class="pd-slide-panel-header">
            <div class="pd-slide-panel-header__body" v-text="title"></div>
            <div class="pd-slide-panel-header__close pd-link"  @click="close">
              <Icon name="Close" style="width:20px" />
            </div>
          </div>
          <!--BODY-->
          <div ref="body" class="pd-slide-panel-body">
            <slot></slot>
          </div>
          <!--FOOTER-->
          <div class="pd-slide-panel-footer">
            <slot name="footer"></slot>
          </div>
        </div>
    </transition>
  </div>
</teleport>
</template>

<script>
import Icon from '@/components/ui/Icon.vue'

export default {
  name: 'ModalPanel',
  components: {
    Icon
  },
  props: {
    title: String,
    large: Boolean,
    full: Boolean,
    autoOpen: Boolean
  },
  emits: ['opened','closed'],
  data: ()=>({
    outerVisible: false,
    visible: false
  }),
  computed: {
  },

  watch: {
  },

  async mounted() {
    if (this.autoOpen) this.open()
  },

  methods: {
    open() {
      this.outerVisible = true
      this.visible = true
    },
    close () {
      this.visible = false
    },
    async closed() {
      await this.$nextTick()
      this.outerVisible = false
      this.$emit("closed")
    }
  }
}
</script>
<style lang="sass">
  @import "./style.sass"
</style>
