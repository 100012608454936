import { computed, ref } from "vue"
import UsersCache from "./users-cache"
import QueriesNats from "./api/queries/nats"

export default class Reminder {
  constructor(usersCache) {
    this.config = null
    /** @type {QueriesNats} */
    this.queries = null
    /** @type {UsersCache} */
    this.usersCache = usersCache

    this.message = ref(null)
    this.contact = ref(null)
    this.info = ref(null)

    this.showVideoChat = ref(null)
    this.initChildMessage()
  }

  close() {
    this.message.value = null
  }

  // Reminder:
  // type: remindVideoCall
  // roomId: this.data.roomId,
  // bookId: roomForVC.BOOK_ID,
  // timeFrom: roomForVC.TIME_FROM  
  async show( data ) {
    let info = await this.queries.selectRow( 'MD_GET_SCHEDULE_SLOT_INFO', { SCHEDULE_SLOT_ID: data.bookId } )
    this.contact.value = await this.usersCache.get(info.USER_ID)
    this.info.value = info
    this.message.value = data
  }

  getVideoChatUrl(params) {
    console.log("getVideoChatUrl",params)
    const baseUrl = this.config.videochat
    const brand = this.config.clinicName
    const postFixUrl = '?audio=1&video=1&fs=0&invite=0&prefs=1&share=0&chat=0&playsinline=1'
    let url = baseUrl + params.roomId + postFixUrl
    if ( brand ) url += `&brand=${ brand }`
    return url
  }

  async showVideochat( params ) {
      // TODO: тест Открытия в новом окне
      let appType = ( typeof ( app ) !== 'undefined' || window.webkit !== undefined )
      if ( appType ) {
        //NewWindow
        window.open( this.getVideoChatUrl(params), '_blank' )
      } else {
        //InApp
        //this.$store.commit( 'videoConf/open', params.roomId )
        this.showVideoChat.value = params
      }
      await this.queries.query( 'MD_START_VIDEO_CHAT', { room_id: params.roomId } )
      this.show = false
      this.close()
  }

  async closeVideoChat() {
    if (!this.showVideoChat.value) return
    await this.queries.query( 'MD_END_VIDEO_CHAT', { room_id: this.showVideoChat.value.roomId } )
    this.showVideoChat.value = null
  }

  initChildMessage() {
    //TODO: deinit
    window.addEventListener( 'message', async ( event ) => {
      if ( event.origin.startsWith( 'https://conf.docsme.ru' ) ) { //check the origin of the data!
        console.log("message from https://conf.docsme.ru", event.data )
        const data = event.data
        const type = data?.type
        if ( type === 'close' ) {
          await this.queries.query( 'MD_END_VIDEO_CHAT', { room_id: Number( data.id ) } )
        }
      } else {
        return
      }
    })
  }
}

// this.eventCall = ( messageJson ) => { this.eventerMessage( messageJson ) }
// eventer.$on( 'message', this.eventCall )
// if ( this.slotId ) {
//   await this.getInfo()
// }
// eventer.$off( 'message', this.eventCall )

// async eventerMessage( messageJson ) {
//   if ( messageJson.type === 'remindVideoCall' ) {
//     this.roomId = messageJson.room_id
//     this.eventSlotId = messageJson.schedule_slot_id
//     this.type = messageJson.type
//     await this.getInfo()
//     console.log( '----> remindVideoCall', messageJson )
//   }
//   if ( messageJson.type === 'stopVideoCall' ) {
//     this.roomId = messageJson.room_id
//     this.eventSlotId = messageJson.schedule_slot_id
//     this.type = messageJson.type
//     await this.getInfo()
//     console.log( '----> stopVideoCall', messageJson )
//   }
//   if ( messageJson.type === 'startVideoCall' ) {
//     this.roomId = messageJson.room_id
//     this.eventSlotId = messageJson.schedule_slot_id
//     this.type = messageJson.type
//     await this.getInfo()
//     console.log( '----> startVideoCall', messageJson )
//   }
// }