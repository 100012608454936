<template>
<div class="flex-grow">
  <img ref="img" :src="imgUrl" style="display: none" />
</div>
</template>
<script>
import Viewerjs from 'viewerjs'
import { backend } from '@/main'

export default {
  name: "ImageView",
  props: {
    attach: Object
  },
  computed: {
    imgUrl() {
      return backend.attachModal.getDownloadUrl(this.attach.ATTACHMENT_ID, this.attach.ATTACH_FILENAME)
    }
  },
  mounted() {
    this.viewer = new Viewerjs(this.$refs.img, {
      inline: true,
      navbar: false,
      title: false,
      fullscreen: false,
      button: false,
      backdrop: false,
      transition: false,
      toolbar: {
        zoomIn: 1,
        zoomOut: 1,
        oneToOne: 0,
        reset: 0,
        prev: 0,
        play: 0,
        next: 0,
        rotateLeft: 1,
        rotateRight: 1,
        flipHorizontal: 0,
        flipVertical: 0,        
      },      
    })
  },
  beforeUnmount() {
    this.viewer.destroy()
  }
}
</script>
<style lang="sass">
  @import 'viewerjs/src/index.css'
</style>