<template>
  <div :class="{pointer: showProfile}"
       :style="{width: size + 'px', height: size + 'px'}"
       class="pd-chats-list-item__photo"
       >

    <img v-if="contact && contact.avatar_url && contact.is_accepted"
         :height="size"
         :src="contact.avatar_url"
         :width="size"/>

    <!--// no foto-->
    <svg v-else-if="contact && firstLetter && contact.is_accepted"
         :height="size"
         :width="size"
         viewBox="0 0 50 50"
         xmlns="http://www.w3.org/2000/svg">
      <rect fill="#dedede"
            height="50"
            width="50"/>
      <text dy="8"
            fill="rgba(0,0,0,0.6)"
            font-family="sans-serif"
            font-size="19"
            font-weight="bold"
            text-anchor="middle"
            x="50%"
            y="50%">{{ firstLetter }}
      </text>
    </svg>

    <!--нет firstLetter-->
    <svg v-else :height="size" :width="size" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <circle cx="25" cy="25" fill="#ececec" r="25"/>
      <path
          d="M34.46,23.16H15.54s.77-8.95,3.16-10.09c1.53-.72,4.6,2.53,6.3,2.53s4.77-3.25,6.3-2.53C33.69,14.21,34.46,23.16,34.46,23.16Z"
          fill="none" stroke="rgba(0,0,0,0.6)"
          stroke-miterlimit="10"/>
      <line fill="none" stroke="rgba(0,0,0,0.6)" stroke-miterlimit="10" x1="15.95" x2="34.05" y1="20.01" y2="20.01"/>
      <rect fill="none" height="3.15" rx="1.58" stroke="rgba(0,0,0,0.6)" stroke-miterlimit="10" width="29" x="10.5"
            y="23.16"/>
      <circle cx="19.33" cy="33.25" fill="none" r="3.78" stroke="rgba(0,0,0,0.6)" stroke-miterlimit="10"/>
      <circle cx="30.67" cy="33.25" fill="none" r="3.78" stroke="rgba(0,0,0,0.6)" stroke-miterlimit="10"/>
      <path d="M22.8,31.75a6.4,6.4,0,0,1,4.4,0" fill="none" stroke="rgba(0,0,0,0.6)" stroke-miterlimit="10"/>

    </svg>

    <!--PROGRESS-->
    <svg v-if="progress && grade" :height="size"
         :width="size" class="pd-chats-list-item__progress" xmlns="http://www.w3.org/2000/svg">

      <circle :cx="size/2" :cy="size/2" :r="size/2 - 2" fill="none" stroke="rgba(255,255,255,.7)"
              stroke-width="4"></circle>

      <circle :cx="size/2"
              :cy="size/2"
              :r="size/2 - 2"
              :stroke="progressCalc(gradeProgress, size/2 - 2).color"
              :stroke-dasharray="progressCalc(gradeProgress, size/2 - 2).dasharray"
              :stroke-dashoffset="progressCalc(gradeProgress, size/2 - 2).dashoffset"
              :transform="'rotate(145 ' + size/2 + size/2 + ' ' + size/2 + size/2 + ')'"
              fill="none"
              stroke-width="4"
              style="transform: rotate(145deg); transform-origin: center center"
              transform-origin="0 0"/>

    </svg>

    <!--Status-->
    <div v-if="isOnline" class="pd-chats-list-item__status online"></div>

  </div>
</template>

<script>
import { carabi } from '@/main'

export default {
  name: 'UserAva',

  props: {

    showProfile: {
      type:    Boolean,
      default: false
    },

    progress: {
      type:    Boolean,
      default: false
    },

    grade: {
      type:    [ Number, String ],
      default: 0
    },

    size: {
      type:    Number,
      default: 50
    },

    contact: {
      type:    Object,
      default: () => {}
    }
  },

  computed: {
    gradeProgress() {return this.grade},
    isOnline() {
      if ( !this.contact ) return false
      return !!( this.contact && this.contact.is_online === true || this.contact.is_online === 'true' )
    },
    selectedContact() {return this.contact.profile_id},
    firstLetter() {
      return this.getFirstLetter( this.contact )
    }
  },

  methods: {

    progressCalc( progress, radius ) {
      let angle = parseFloat( progress ) * 36 || 0

      let color = '#008af4'

      const warning = '#fcc307',
            danger  = '#ff6021',
            success = '#69a50a'

      if ( progress < +3 ) {
        color = danger
      }
      else if ( progress >= +3.3 && progress < +6.6 ) {
        color = warning
      }
      else if ( progress === +10 ) {
        color = success
      }

      //const radius          = 23
      const circumference   = 2 * Math.PI * radius
      const strokeOffset    = ( 1 / 4 ) * circumference
      const strokeDasharray = ( angle / 360 ) * circumference

      return {
        color:      color,
        dasharray:  [ strokeDasharray, circumference - strokeDasharray ],
        dashoffset: strokeOffset
      }
    },

    getFirstLetter( item ) {
      if ( !item ) return null
      let result = []
      if ( item.last_name ) {
        result.push( item.last_name.slice( 0, 1 ) )
      }
      else if ( item.LAST_NAME ) result.push( item.LAST_NAME.slice( 0, 1 ) )
      if ( item.first_name ) {
        result.push( item.first_name.slice( 0, 1 ) )
      }
      else if ( item.FIRST_NAME ) result.push( item.FIRST_NAME.slice( 0, 1 ) )
      if ( !result.length ) return null
      return result.join( '' )
    }
  }
}
</script>