import AppserverClass from './appserver'
import GatewayBase from './gatewayBase'

import IQueries from './queries'
import QueriesAppserv from './queries/appserver'
import QueriesNats from './queries/nats'

import IAuth from './auth'
import AuthAppserv from './auth/appserver'
import AuthNats from './auth/nats'

export default class Api {
    constructor() {
        this.config = null
        this.defaultProject = null
    }

    init(config) {
        this.config = config
        this.defaultProject = config.project

        /** @type {GatewayBase} */
        this.gateway = null
        if (config.gateway) {
            console.log('init gateway',config.gateway)
            this.gateway = new GatewayBase()
            this.gateway.setAddress(config.gateway, this.defaultProject)
        }
        if (config.appserver) {
            console.log('init appserver',config.appserver)
            this.appserver = new AppserverClass(config.appserver, this.defaultProject)
        }

        /** @type IQueries */
        this.queries = null
        if (config?.api?.queries) {
            if (config.api.queries === "appserv") {
                console.log('init queries appserv',this.appserver)
                this.queries = new QueriesAppserv(this.appserver)
            }
            else if (config.api.queries === "gateway") {
                console.log('init queries nats',this.gateway)
                this.queries = new QueriesNats(this.gateway)
            } else {
                throw new Error("wrong config.api.queries")
            }
        } else {
            throw new Error("config.api.queries not set")
        }

        /** @type IAuth */
        this.auth = null
        console.log('init auth',config?.api?.auth)
        if (config?.api?.auth) {
            if (config.api.auth === "appserv") {
                console.log('init auth appserv',this.appserver)
                this.auth = new AuthAppserv(this.appserver)
            }
            else if (config.api.auth === "gateway") {
                console.log('init auth nats',this.gateway)
                this.auth = new AuthNats(this.gateway)
            } else {
                throw new Error("wrong config.api.auth")
            }
        } else {
            throw new Error("config.api.auth not set")
        }
    }

    setToken(newToken, project=null) {
        console.log('api setToken',newToken, project)
        if (this.gateway) {
            this.gateway.setToken(newToken, project || this.defaultProject)
        }
        if (this.appserver) {
            this.appserver.project = project
            this.appserver.token  = newToken
        }
    }

    getToken() {
        if (this.gateway) {
            return this.gateway.token
        } else if (this.appserver) {
            return this.appserver.token
        }
    }

    async login(login, password, project=null) {
        //Проект по-умолчанию
        project = project || this.defaultProject
        let newToken = await this.auth.login(project, login, password)
        console.log("newToken",newToken)
        this.setToken(newToken,project)
    }

    async logout() {
        try {
            await this.auth.logout()
        } catch (e) {
            console.error("Logout Error",e)
        }
        this.setToken(null)
    }
}