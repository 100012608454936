import {carabi} from "@/main"
import { reactive, ref } from "vue"
import Event from '@/utils/event'

export default class Eventer {
  constructor() {
    this.reconnectTime = 1000
    this.address = null
    this.token = null

    this.status = ref('closed')
    this.enabled = false
    this.authorised = false
    this.reconnectTimer = null
    this.socket = null
    this.onConnected = new Event()
    this.onMessage = new Event()
  }

  enable() {
    if (this.enabled) return
    this.enabled = true
    this.createSocket()
  }

  disable() {
    if (!this.enabled) return
    this.enabled = false
    this.socketClosed()
    if (this.reconnectTimer) {
      clearTimeout(this.reconnectTimer)
      this.reconnectTimer = null
    }
  }

  async waitConnect() {
    return new Promise((resolve,reject)=>{
      var conn = ()=> {
        this.onConnected.off(resolve)
        resolve()
      }
      this.onConnected.on(conn)
    })
  }

  createSocket() {
    console.log("Eventer open",this.address)
    this.socket = new WebSocket(this.address)
    this.authorised = false
    //Событие при открытии сокета
    this.socket.onopen = ()=> {
      this.socketConnected();
    };
    //Событие при закрытии сокета
    this.socket.onclose = (event)=> {
      console.log("Eventer closed", event.wasClean, event);
      this.socketClosed()
    }
    //Ошибка сокета (когда не может подключиться)
    this.socket.onerror = (error)=> {
      console.error("Eventer error",error);
      this.socketClosed()
    }
    //Пришло сообщение
    this.socket.onmessage = (event)=> {
      this.socketMessage(event);
    }
    this.status.value = "connecting";
  }

  socketClosed() {
    console.log("Eventer closed")
    this.status.value = "closed"
    this.authorised = false
    if (this.socket !== null) {
      this.socket.onclose = ()=>{}
      this.socket.close()
      this.socket = null
    }
    if (this.enabled) {
      this.reconnectTimer = setTimeout(()=>{
        if (this.reconnectTimer !== null) {
          clearTimeout(this.reconnectTimer);
          this.reconnectTimer = null;
        }
        this.createSocket()
      },this.reconnectTime)
    }
  }

  socketConnected() {
    //console.log("Eventer auth",this.token);
    let pack = {
      "type" : "auth",
      "token" : this.token
    };
    this.socket.send(JSON.stringify(pack));
  }

  socketMessage(event) {
    let messageJson = event.data.split('undefined').join('null') //Костыль?
    messageJson = JSON.parse(messageJson)
    if (!this.authorised) {
      if (messageJson.type === "auth_success") {
        console.log("Eventer ready")
        this.authorised = true
        this.status.value = "ready"
        this.onConnected.emit()
      } else {
        console.log("Eventer auth error",messageJson)
        this.socketClosed();
      }
    } else {
      console.log("Eventer message",event.data)
      this.onMessage.emit(messageJson)
    }
  }  

}