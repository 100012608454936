<template>
<div class="pd-files-tags">
  <div v-for="item in items"
       :key="item[itemId]"
       :class="{active: curValue === item.id}"
       class="pd-files-tags-item"
       @click="select(item)">
    <span class="pd-files-tags-item__label" v-text="item.label"></span>
    <span v-if="item.count" v-text="item.count" class="pd-files-tags-item__count"></span>
  </div>
</div>
</template>
<script>
export default {
  name: "Tags",
  props: {
    items: Array,
    itemId: {
      type: String,
      default: "id"
    },
    value: [String, Number]
  },
  data: ()=>({
    curValue: null
  }),
  created() {
    this.curValue = this.value
  },
  computed: {
    curItem() {
      return this.items.find(i => i[this.itemId] === this.curValue)
    }
  },
  watch: {
    value() {
      if (this.value === this.curValue) return
      this.curValue = this.value
    }
  },
  methods: {
    select(item) {
      this.curValue = item.id
      this.$emit("changed", this.curItem)
    }
  }
}
</script>
