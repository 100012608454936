<template>
  <modal ref="modal" @closing="closing()" :width="300" modalClass="modal-popup">
    <div class="message" v-html="message"></div>
    <div class="buttons">
      <button v-if="isConfirm" @click="no" class="pd-button" type="button">Нет</button>
      <button v-if="isConfirm" @click="yes" class="info pd-button" type="button">Да</button>      
      <button v-if="!isConfirm" @click="yes" class="info pd-button" type="button">OK</button>      
    </div>
  </modal>
</template>
<script>
  import { backend } from '@/main'
  import Modal from './Modal'

  class ModalPopupsVue {
    constructor(vue) {
      this.vue = vue
    }

    async alert(message) {
      return this.vue.alert(message)
    }

    async confirm(message) {
      return this.vue.confirm(message)
    }    
  }
  
  export default {
    name: 'ModalPopups',  
    components: {
      Modal
    },  
    props: {
    },
    data: ()=>({
      message: '',
      isConfirm: false,
      confirmed: false
    }),    
    async mounted() {
      this.reset()
      backend.popups = new ModalPopupsVue(this)
    },  
    methods: {
      async alert(message) {
        this.reset()
        let resolve = null
        let prom = new Promise((reslv)=>{ resolve = reslv })
        this.message = message
        this.callback = resolve
        this.$refs.modal.open()
        return prom
      },

      async confirm(message) {
        this.reset()
        let resolve = null
        let prom = new Promise((reslv)=>{ resolve = reslv })
        this.isConfirm = true
        this.message = message
        this.callback = resolve
        this.$refs.modal.open()
        return prom
      },

      reset() {
        this.message = ''
        this.isConfirm = false
        this.confirmed = false
        this.callback = ()=>{}
      },

      yes() {
        this.confirmed = true
        this.$refs.modal.close()
      },

      no() {
        this.confirmed = false
        this.$refs.modal.close()
      },

      closing() {
        this.callback(this.confirmed)
      }
    }
  }
  </script>
  <style lang="sass">
  .modal-popup
    .message
      padding: 10px
      text-align: center
    .buttons
      padding-top: 10px
      padding-bottom: 10px
      text-align: center
      button
        min-width: 110px
  </style>