const DefaultTheme = {
  'color-primary-100': '#EFFACB',
  'color-primary-200': '#DDF699',
  'color-primary-300': '#BCE363',
  'color-primary-400': '#97C93B',
  'color-primary-500': '#69A50A',
  'color-primary-600': '#548D07',
  'color-primary-700': '#417605',
  'color-primary-800': '#305F03',
  'color-primary-900': '#254F01',
  'color-success-100': '#EFFACB',
  'color-success-200': '#DDF699',
  'color-success-300': '#BCE363',
  'color-success-400': '#97C93B',
  'color-success-500': '#69A50A',
  'color-success-600': '#548D07',
  'color-success-700': '#417605',
  'color-success-800': '#305F03',
  'color-success-900': '#254F01',
  'color-info-100':    '#CBF3FE',
  'color-info-200':    '#98E2FD',
  'color-info-300':    '#64CAFB',
  'color-info-400':    '#3EB1F8',
  'color-info-500':    '#008af4',
  'color-info-600':    '#006AD1',
  'color-info-700':    '#004FAF',
  'color-info-800':    '#00388D',
  'color-info-900':    '#002875',
  'color-warning-100': '#FEF8CD',
  'color-warning-200': '#FEEE9B',
  'color-warning-300': '#FEE269',
  'color-warning-400': '#FDD644',
  'color-warning-500': '#fcc307',
  'color-warning-600': '#D8A205',
  'color-warning-700': '#B58303',
  'color-warning-800': '#926602',
  'color-warning-900': '#785101',
  'color-danger-100':  '#FFEBD2',
  'color-danger-200':  '#FFD1A6',
  'color-danger-300':  '#FFB179',
  'color-danger-400':  '#FF9358',
  'color-danger-500':  '#ff6021',
  'color-danger-600':  '#DB4218',
  'color-danger-700':  '#B72910',
  'color-danger-800':  '#93150A',
  'color-danger-900':  '#7A0706'
}

export default class Theme {
  constructor() {
    this.name = "meddoc"
  }

  async load() {    
    let theme = {...DefaultTheme}
    try {
      let resp = await fetch(`/theme/custom-theme.json`)
      let respTheme = await resp.json()
      theme = {...theme, ...respTheme}
    } catch(e) {
      console.error(e)
    }
    for ( let prop in theme ) {
      document.documentElement.style.setProperty( `--${ prop }`, theme[ prop ] )
    }
  }
}