<template>
  <div v-if="loaded" class="pd-video-conf-wrap">

    <div class="pd-video-conf-wrap__header flex-row" style="gap: 1rem; align-self: stretch;">
      <a class="pd-video-conf-wrap__link"
         @click="$emit('back')">
        <Icon name="Back" :size="16"/>
        <span v-text="'выбрать другой день'"></span>
      </a>
      <div class="flex-grow" style="text-align: right;">
        <strong class="pd-video-conf-wrap__text pd-video-conf-wrap__text--right" v-text="dayF"></strong>
      </div>
    </div>

    <h2 class="pd-video-conf-title"
        v-text="'Выберите удобное время приема'"></h2>

    <div class="pd-video-conf-days pd-video-conf-wrap__body">

      <div v-for="item in formattedTimes" :key="item.time"
           :class="{
             'pd-video-conf-day--selected': item.FROM_TIME === active?.time
           }"
           class="pd-video-conf-day"
           role="button"
           @click="select(item)">
        <span v-text="item.time"></span>
        <small class="text-muted" v-text="item.FILIAL_NAME"></small>
      </div>
    </div>

    <button v-if="!process" :disabled="!active"
            class="pd-video-conf-wrap__footer pd-button not-pill success"
            @click="submit"
            v-text="actionText"></button>
    <button v-else :disabled="true" class="pd-video-conf-wrap__footer pd-button not-pill success">
      <loader />    
    </button>            
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { backend } from '@/main'
import Loader from '@/components/ui/Loader.vue'
export default {
  name:       'NewVisitSelectSlot',
  components: {Loader},
  props:      {
    visitType: Number,
    day: { type: Date, default: null },
    doctor: Object,
    process: Boolean
  },
  data() {
    return {
      loaded: false,
      times: [],
      active: null
    }
  },

  computed: {
    actionText() { return 'Выбрать' },

    dayF() { return dayjs(this.day).format('DD.MM.YYYY') },

    formattedTimes() {
      if ( !this.times || !this.times.length ) return null
      let times = []
      this.times.forEach( i => {
        times = [ ...times, {
          FROM_TIME:   i.FROM_TIME,
          FILIAL_ID:   i.FILIAL_ID,
          FILIAL_NAME: i.FILIAL_NAME,
          clinic:      i.CLINIC_ID,
          time:        dayjs(i.FROM_TIME).format('HH:mm')
        } ]
      } )
      return times
    },
  },

  async created() {
    await this.reload()
  },

  methods: {
    async reload() {
      const result = await backend.api.queries.select( 'MD_GET_DOCTOR_WS_BY_DAY', {
        doctor_id:  this.doctor.profile_id,
        date:       dayjs(this.day).toISOString(),
        visit_type: this.visitType
      }, 200 )
      this.times = result
      this.loaded = true
    },

    select( item ) {
      if ( this.active?.time === item.FROM_TIME ) {
        this.active = null
      }
      else {
        this.active = {
          time: item.FROM_TIME,
          clinic: item.clinic,
          filial_id: item.FILIAL_ID
        }
      }
    },

    submit() {
      if ( !this.active || this.process ) return
      this.$emit( 'select', this.active )
    },
  }
}
</script>