import { defineAsyncComponent } from 'vue'

const groupViews = { 
  MAIN: defineAsyncComponent( () => import(/* webpackChunkName: "GroupViewMain" */'./MAIN/index.vue') ),
  HTML_EDITOR: defineAsyncComponent( () => import(/* webpackChunkName: "GroupViewHtml" */'./Html/index.vue') ),
  md_agreement: defineAsyncComponent( () => import(/* webpackChunkName: "GroupViewAGREEMENTS" */'./Agreements.vue') ),
  md_doc_files: defineAsyncComponent( () => import(/* webpackChunkName: "GroupViewDocFiles" */'./DocFiles.vue') ),
  md_history_requests: defineAsyncComponent( () => import(/* webpackChunkName: "GroupViewHistoryRequests" */'./HistoryRequests.vue') ),
  md_client_requests: defineAsyncComponent( () => import(/* webpackChunkName: "GroupViewClientRequests" */'./ClientRequests.vue') ),
  pg_calendar_work: defineAsyncComponent( () => import(/* webpackChunkName: "GroupViewCalendarWork" */'./CalendarWork/index.vue') ),
  pg_regime_work: defineAsyncComponent( () => import(/* webpackChunkName: "GroupViewCalendarWork" */'./RegimeWork/index.vue') ),
}
export default groupViews