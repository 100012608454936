import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../routes/home'
import { backend } from '@/main'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/auth',
    name: 'Auth',
    component: ()=>( import(/* webpackChunkName: "RouteAuth" */ './Auth')),
    meta: {
      auth: true,
      title: 'Авторизация'
    }
  },
  {
    path: '/chats',
    name: 'Chats',
    component: ()=>( import(/* webpackChunkName: "RouteChats" */ './Chats')),
    meta: {
      layout: true,
      title: 'Сообщения'
    },
    children: [
      {
        path: ':roomId',
        name: 'ChatRoom',
        component: ()=>( import(/* webpackChunkName: "RouteChats" */ './Chats')),
        meta: {
          layout: true,
          title: 'Сообщения'
        }        
      }
    ],
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: ()=>( import(/* webpackChunkName: "RouteContacts" */ './Contacts')),
    meta: {
      layout: true,
      title: 'Контакты'
    },
    children: [
      {
        path: ':contactId',
        name: 'Contact',
        component: ()=>( import(/* webpackChunkName: "RouteContacts" */ './Contacts')),
        meta: {
          layout: true,
          title: 'Контакты'
        }        
      }
    ],
  },
  {
    path: '/contacts-clinic',
    name: 'ContactsClinicIndex',
    component: ()=>( import(/* webpackChunkName: "RouteContactsClinic" */ './ContactsClinic')),
    meta: {
      layout: true,
      title: 'Выбор врача'
    },
    children: [
      {
        path: ':contactId',
        name: 'ContactClinic',
        component: ()=>( import(/* webpackChunkName: "RouteContactsClinic" */ './ContactsClinic')),
        meta: {
          layout: true,
          title: 'Выбор врача'
        }        
      }
    ],
  },
  {
    path: '/files',
    name: 'Files',
    component: ()=>( import(/* webpackChunkName: "RouteFiles" */ './Files')),
    meta: {
      layout: true,
      title: 'Файлы'
    },
  },
  {
    path: '/booking-patient',
    name: 'BookingPatient',
    component: ()=>( import(/* webpackChunkName: "BookingPatient" */ './BookingPatient')),
    meta: {
      layout: true,
      title: 'Записи на прием'
    }    
  },
  {
    path: '/booking-doctor',
    name: 'BookingDoctor',
    component: ()=>( import(/* webpackChunkName: "BookingDoctor" */ './BookingDoctor')),
    meta: {
      layout: true,
      title: 'Записи на прием'
    }    
  },
  {
    path:      '/medical-record',
    name:      'MedicalRecord',
    component: ()=>( import(/* webpackChunkName: "MedicalRecord" */ './MedicalRecord')),
    meta: {
      layout: true,
      title: 'Медицинская карта'
    }    
  },
  {
    path: '/ui-tests',
    name: 'uitests',
    component: ()=>( import(/* webpackChunkName: "RouteUiTests" */ './UiTests')),
    meta: {
      layout: true
    }
  },
  {
    path: '/card-test/:documentId/:cardId',
    name: 'cardtest',
    component: ()=>( import(/* webpackChunkName: "RouteCardTest" */ './CardTest')),
    meta: {
      layout: true
    }
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: ()=>( import(/* webpackChunkName: "RouteMyProfile" */ './MyProfile')),
    meta: {
      layout: true,
      title: 'Мой профиль'
    }
  },
  {
    path: '/billing',
    name: 'Billing',
    component: ()=>( import(/* webpackChunkName: "Billing" */ './Billing')),
    meta: {
      layout: true,
      title: 'Платежи'
    }
  },
  {
    path: '/registration',
    name: 'Registration',
    component: ()=>( import(/* webpackChunkName: "Registration" */ './Registration')),
    meta: {
      layout: false,
      title: 'Регистрация'
    }
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: ()=>( import(/* webpackChunkName: "Subscribe" */ './Subscribe')),
    meta: {
      layout: false,
      title: 'Оплата подписки'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  /*scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  }*/  
})
router.beforeEach((to, from) => {
  //console.log('beforeEach',to)
  if (!backend.loaded) return
  let redirect = backend.checkRoute(to)
  if (redirect) return redirect
    document.title = to.meta?.title || to.name
})

export default router