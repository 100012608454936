<template>
  <div class="pd-sidebar-outer flex-center" key="offline">
    <div style="width: 200px; text-align: center">
      <IconOffLine style="width: 120px; height: auto" />
      <h3 class="margin-top" v-text="'Идет подключение к Интернет....'"></h3>
      <Loader :size="40" />
    </div>
  </div>
</template>
<script>
import IconOffLine from './IconOffLine.vue'
import Loader from '../ui/Loader.vue'
export default {
  components: {
    IconOffLine,
    Loader
  }
}
</script>
