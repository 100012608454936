<template>
<ModalPanel v-if="opened" @closed="closed" :title="filename" :full="true" :auto-open="true">
  
  <ImageView v-if="attachType === 'image'" :attach="attach" />  
  <PdfView v-else-if="attachType === 'pdf'" :attach="attach" />  
  <div v-else class="flex-grow">{{attachType}}</div>

  <div v-if="!editDescr" class="flex-row pd-modal-action">
    <div class="pd-badge primary flex-none" v-if="tag" v-text="tag"></div>
    <div class="flex-row flex-grow" style="justify-content: center; cursor: pointer" @click="editDescr=true">
      <div>{{descrView}}</div>
      <div><icon name="Edit" :size="20" style="margin-left: 0.5rem; color: var(--color-link)" /></div>
    </div>
    <div class="pd-modal-action__main">
      <a @click.prevent="download" href="#"
        target="_blank"
        class="pd-button warning small"
        v-text="'Скачать'"></a>
    </div>
  </div>

  <form v-else @submit.prevent="saveDescr" class="flex-row pd-modal-action">
    <input ref="descrEdit" type="text" autofocus placeholder="Введите описание файла" class="pd-field-item__input flex-grow" v-model="newDescr" />
    <div class="pd-modal-action__main">
      <button type="submit"
        class="pd-button success small"
        v-text="'Сохранить'"></button>
    </div>
  </form>


</ModalPanel>
</template>
<script>
import { backend } from '@/main'
import ModalPanel from '@/modals/ModalPanel'

import ImageView from './image'
import PdfView from './pdf'

export default {
  name: "AttachViewModal",
  components: {
    ModalPanel,
    ImageView,
    PdfView
  },
  data: ()=>({
    editDescr: false,
    newDescr: null
  }),
  computed: {
    tag() {
      return this.attach?.FILE_TAG
    },
    opened() {
      return backend.attachModal.data.opened
    },
    attach() {
      return backend.attachModal.data.attach
    },
    attachType() {
      return backend.attachModal.data.type
    },
    documentId() {
      return backend.attachModal.data.documentId
    },    
    filename() {
      return this.attach?.ATTACH_FILENAME
    },
    descrView() {
      return this.attach?.ATTACH_DESCR || 'Нет описания'
    }
  },
  watch: {
    opened() {
      if (!this.opened) return
      this.newDescr = this.attach?.ATTACH_DESCR || ''
    },
    descrEdit() {
      if (!this.descrEdit) return
      setTimeout(()=>{
        this.$refs?.descrEdit?.focus()
      },0)
    }
  },
  created() {},  
  methods: {
    closed() {
      backend.attachModal.data.opened = false
    },
    download() {
      backend.attachModal.download(this.attach)
    },
    async saveDescr() {
      backend.api.queries.query('MD_UPDATE_COMMENT_ATTACH',{
        DOC_ID: this.documentId || 0,
        ATTACH_ID: this.attach.ATTACHMENT_ID,
        IS_LINK: this.attach.IS_LINK || 0,
        COMMENTS: this.newDescr,
      })
      this.attach.ATTACH_DESCR = this.newDescr
      this.editDescr=false
    }
  }
}
</script>