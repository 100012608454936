<template>
  <div class="pd-video-conf-wrap">
    <div class="pd-video-conf-wrap__body flex column center middle"
        style="justify-content: center">
      <h2 class="pd-video-conf-title"
          v-text="'У Вас уже есть запись'"></h2>
      <div class="pd-video-conf-record">        
        <div class="pd-video-conf-record__date" v-text="formatDate"></div>
        <div class="pd-video-conf-record__date" v-text="formatTime"></div>
        <div class="pd-video-conf-record__actions">
          <button class="pd-button danger"
                  @click="cancel"
                  v-text="'Отменить'"></button>
          <button class="pd-button info"
                  @click="move"
                  v-text="'Перенести'"></button>
        </div>
      </div>
    </div>
    <button class="pd-video-conf-wrap__footer pd-button not-pill"
            @click="$emit('close')"
            v-text="'Закрыть'"></button>
  </div>
</template>

<!-- "record": {
  "selectDay": "Выберите удобный день приема",
  "backToDays": "выбрать другой день",
  "emptyDay": "К сожалению нет доступных дней для записи",
  "success": "Вы успешно записались!",
  "error": "В данный момент не возможно записаться. Попробуйте попозже",
  "selectTime": "Выберите удобное время приема",
  "emptyTime": "К сожалению в данный день, нет свободного времени",
  "actionSelectDay": "Выбрать время приема",
  "actionSelectTime": "Выбрать время приема",
  "selectAnotherDay": "Выбрать другой день",
  "listTitle": "У Вас уже есть запись",
  "cancelMessage": "Запись успешно отменена!",
  "cancel": "Отменить",
  "move": "Перенести"
}, -->

<script>
import dayjs from 'dayjs';
import { backend } from '@/main';

export default {
  name: 'NewVisitCurrentSlot',

  props: {
    bookSlot:  { type: Object, default: null }
  },

  computed: {
    formatDate () {
      return dayjs(this.bookSlot.time).format('D MMMM YYYY[,] dddd')
    },
    formatTime () {
      return dayjs(this.bookSlot.time).format('HH:mm')
    }
  },

  methods: {
    async cancel() {
        await backend.api.queries.query( 'MD_CANCEL_BOOK', { BOOK_ID: this.bookSlot.BOOK_ID } )
        backend.notifications.add("Запись успешно отменена",'success')
        this.$emit("close")
    },

    async move() {
      await backend.api.queries.query( 'MD_CANCEL_BOOK', { BOOK_ID: this.bookSlot.BOOK_ID } )
      this.$emit("next")
    }
  }
}
</script>
<style lang="sass">
@import "~@/sass/variables"

.pd-video-conf-record
  margin-top: $global-margin
  display: flex
  align-items: center
  flex-direction: column
  gap: $global-margin-large

  &__date
    font-weight: bold

  &__actions
    display: flex
    align-items: center
    gap: $global-margin-small
</style>