class Popups {

  async alert(message) {
    alert(message)
    //await UIkit.modal.alert(message,{ stack: true })
  }

  async confirm(message) {
    return confirm(message)
    /*await UIkit.modal.confirm(message, {
      stack: true,
      labels: {ok: '&nbsp;&nbsp;Да&nbsp;&nbsp;', cancel: '&nbsp;&nbsp;Нет&nbsp;&nbsp;'}
    });*/
  }

}

export default Popups
