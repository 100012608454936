import UAParser from 'ua-parser-js'

export default class UserAgent {
    constructor() {
      this.data = new UAParser().getResult()
      console.log('UserAgent',this.data)
    }

    isAndroid() {
      let os = this.data?.os?.name
      return (os && os.toLowerCase() === 'android')
    }    
}