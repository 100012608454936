<template>
  <div class="pd-callbar-outer">
    <transition name="slide-rotate-hor-top" appear mode="out-in">

      <div class="pd-callbar" v-if="show"
           :class="{'pd-callbar--minimize': minimize}">

        <!--Header-->
        <!--        <div class="pd-callbar__header"></div>-->

        <!--BODY-->
        <!--        <div class="pd-callbar__body"></div>-->

        <!-- FOOTER-->
        <div class="pd-callbar__footer">

          <div class="pd-callbar__caller">
            <UserAva class="pd-callbar__caller-ava"
                     :contact="contact"
                     :show-profile="false"
                     :size="60" />

            <div class="pd-callbar__caller-name" v-if="contact" v-text="contact.fullName"></div>
            <div class="pd-callbar__caller-meta" v-if="info" v-text="info.CONTACT_DESCR"></div>
            <div class="pd-callbar__caller-time" v-if="time" v-text="time"></div>

          </div>

          <!--          // remindVideoCall startVideoCalll stopVideoCall-->
          <button class="pd-callbar__action pd-callbar__action--success pd-callbar__action--text"
                  v-if="reminder.type === 'startVideoCall'" @click.prevent="openVideoChat">
            <span class="pd-callbar__action-icon" v-html="icons.callInCome"></span>
            <span class="pd-callbar__action-text" v-text="'Перейти'"></span>
          </button>

          <button class="pd-callbar__action pd-callbar__action--warning pd-callbar__action--text"
                  v-if="reminder.type === 'remindVideoCall'" @click.prevent="moveCall">
            <span class="pd-callbar__action-icon" v-html="icons.calendar"></span>
            <span class="pd-callbar__action-text" v-text="'Изменить'"></span>
          </button>

          <!--          <button class="pd-callbar__action pd-callbar__action&#45;&#45;danger pd-callbar__action&#45;&#45;text"-->
          <!--                  @click.prevent="cancelCall">-->
          <!--            <span class="pd-callbar__action-icon" v-html="icons.callOff"></span>-->
          <!--            <span class="pd-callbar__action-text" v-text="'Отменить'"></span>-->
          <!--          </button>-->

          <button class="pd-callbar__action pd-callbar__action--close" @click.prevent="close">
            <span class="pd-callbar__action-icon" v-html="icons.close"></span>
            <!--            <span class="pd-callbar__action-text" v-text="'close'"></span>-->
          </button>
        </div>
      </div>
    </transition>
    <NewVisit v-if="showNewVisit" @closed="showNewVisit=false" :roomId="newVisitRoomId" :visitType="2" :doctor="{'profile_id':info.DOCTOR_ID}" />
  </div>
</template>
<script lang="js">
//import eventer from '@/api/eventer'
//import { getTimeFromNow } from '@/utils/meddoc-helpers'
import { backend } from '@/main.js'
import UserAva from '@/components/ui/elements/UserAva'
import dayjs from 'dayjs'

import callInComeSvg from './svg/callInCome.svg'
import callOnSvg from './svg/callOn.svg'
import callOffSvg from './svg/callOff.svg'
import closeSvg from './svg/close.svg'
import calendarSvg from './svg/calendar.svg'
import NewVisit from '@/modals/NewVisit'

export function getTimeFromNow( date, todayTime = false, withoutSuffix = false ) {
  //const parseDate = dayjs( date, 'DD.MM.YYYY HH:mm:ss' )
  const parseDate = dayjs( date )
  const dateDay   = parseDate.format( 'YYYY.MM.DD' )
  const todayDay  = dayjs().format( 'YYYY.MM.DD' )
  const isToday   = ( dateDay !== todayDay || todayTime )
    ? parseDate.fromNow( withoutSuffix )
    : 'сегодня'
  return ( parseDate.isValid() ) ? isToday : null
}

export default {
  name: 'CallPanel',

  components: {
    UserAva,
    NewVisit
  },

  data() {
    return {
      showNewVisit: false,
      newVisitRoomId: null,
      minimize:    true,
      icons:       {
        callInCome: callInComeSvg,
        callOn:     callOnSvg,
        callOff:    callOffSvg,
        close:      closeSvg,
        calendar:   calendarSvg
      }
    }
  },

  // Reminder:
  // type: 'remindVideoCall'
  // roomId: this.data.roomId,
  // bookId: roomForVC.BOOK_ID,
  // timeFrom: roomForVC.TIME_FROM

  computed: {
    brand() { return backend.config.clinicName },
    reminder() { return backend.reminder.message.value },
    contact() { return backend.reminder.contact.value },
    info() { return backend.reminder.info.value },
    time() { return ( this.info ) ? getTimeFromNow( this.info.VC_DATE_TIME ) : null },
    show() { return !!this.reminder },
    roomId() { return backend.reminder.message.value.roomId }
  },

  watch: {
  },

  async mounted() {
  },

  methods: {

    close() {
      backend.reminder.close()
    },

    notify( content, type ) {
      backend.notifications.add(content, type)
    },

    async moveCall() {
      this.showNewVisit = true
      this.newVisitRoomId = this.roomId
      this.close()
    },

    async showVideoChat() {
      backend.reminder.openVideoChat({
        roomId: this.roomId
      })
      this.close()
    }
  }

}
</script>

<style lang="sass">
@import "@/sass/variables"
@import "@/sass/mixins"

.pd-callbar-outer
  position: absolute
  top: 0
  left: 0
  right: 0
  height: 0

.pd-callbar
  position: absolute
  width: 96vw
  min-width: 320px
  max-width: 550px
  margin: $global-margin-small $global-margin-xsmall
  left: calc(50% - .25rem)
  transform: translateX(-50%)
  padding: $global-margin-small
  background-color: rgba(44, 60, 85, 0.6)
  color: rgb(255, 255, 255)
  border-radius: $global-margin-small
  z-index: 3000
  backdrop-filter: blur(10px)

  &--minimize
    border-radius: 500px

  &__caller
    flex: 1
    display: grid
    align-items: center
    grid-gap: 0 $global-margin
    line-height: 1.2
    grid-template-columns: auto 1fr
    grid-template-areas: "ava name" "ava meta" "ava time"

  &__caller-ava
    grid-area: ava

  &__caller-name
    grid-area: name
    font-size: .95rem
    align-self: end
    @include ellipsis

  &__caller-meta
    align-self: center
    color: rgba(255, 255, 255, .7)
    font-size: .85rem
    grid-area: meta
    @include ellipsis

  &__caller-time
    align-self: start
    font-size: .85rem
    grid-area: time

  //&__header
  //
  //&__body

  &__footer
    display: flex
    gap: $global-margin-small
    align-items: center
  //justify-content: space-between

  &__action
    overflow: hidden
    min-width: 2rem
    height: 2rem
    display: flex
    align-items: center
    justify-content: center
    background-color: rgba(255, 255, 255, .2)
    border-radius: 500px
    padding: 0
    gap: $global-margin-xsmall
    font-size: .9rem
    align-self: flex-eng

    @media (max-width: $device-iPhone)
      min-width: 3rem
      height: 3rem

    &--text
      padding: 0 .75rem

      @media (max-width: $device-iPhone)
        padding: 0

    &--danger
      background-color: var(--color-danger)

    &--primary
      background-color: var(--color-primary)

    &--success
      background-color: var(--color-success)

    &--warning
      background-color: var(--color-warning)
      color: #000000

  &__action-text
    flex: 1
    min-width: 10px
    @include ellipsis

    @media (max-width: $device-iPhone)
      display: none

  &__action-icon
    flex: none
    width: 1.2rem
    height: 1.2rem

    @media (max-width: $device-iPhone)
      width: 1.5rem
      height: 1.5rem

  svg
    width: 100%
    height: 100%

/* slide-rotate-hor-top
.slide-rotate-hor-top-enter-active
  animation: slide-rotate-hor-top 1s cubic-bezier(0.680, -0.550, 0.265, 1.550) reverse backwards

.slide-rotate-hor-top-leave-active
  animation: slide-rotate-hor-top 1s cubic-bezier(0.680, -0.550, 0.265, 1.550) backwards

@keyframes slide-rotate-hor-top
  0%
    transform: translateX(-50%) translateY(0) rotateX(0deg)
  100%
    transform: translateX(-50%) translateY(-150px) rotateX(-90deg)

</style>