<template>
<svg version="1.1"
       xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink"
       x="0px"
       y="0px"
       viewBox="0 0 100 100"
       enable-background="new 0 0 0 0"
       :width="size"
       :height="size"
       xml:space="preserve">
    <path class="loader"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
      <animateTransform attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="1s"
              from="0 50 50"
              to="360 50 50"
              repeatCount="indefinite" />
  </path>
</svg>
</template>
<script>
  export default {
    name: 'Loader',
    props: {
      size: {
        type:    Number,
        default: 32
      },
    }
  }
</script>
