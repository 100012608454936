<template>
  <div class="pd-sidebar-outer flex-center" style="" key="offline">
    <div style="display: flex; flex-direction: column; align-items: center;">
      <Loader :size="80" />
      <h3 class="margin-top" v-text="'Запуск приложения...'"></h3>
    </div>
  </div>
</template>
<script>
import Loader from '@/components/ui/Loader.vue'
export default {
  components: {
    Loader
  }
}
</script>
