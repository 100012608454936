export default class Modals {
  constructor() {
    this.opened = []    
  }

  getZIndex() {
    let zi = 1000
    for (let o of this.opened) {
      if (o.zindex > zi) zi = o.zindex
    }
    return zi+1
  }

  addModal(modal) {
    this.opened.push({
      vue: modal,
      zindex: modal.zindex
    })
  }

  delModal(modal) {
    let i = this.opened.findIndex(o => o.vue === modal)
    if (i !== -1) this.opened.splice(i,1)
  }

  closeAll() {
    for (let m of this.opened) {
      m.vue.close()
    }
  }  
}