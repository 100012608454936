<template>
  <component v-if="groupView" :is="groupView"
      ref="groupView"
      @editing="groupEditing($event)"
      :group="group"
      :documentInfo="documentInfo"
      :cardModel="cardModel"
  />
  <div v-else><h3>Plugin {{this.group?.GROUP_PLUGIN_NAME}} not found</h3></div>
</template>
<script>
  import {backend} from '@/main'
  import groupViews from './groupViews'
  
  export default {
    name: 'DocCardGroup',
    components: {
    },
    props: {
      documentInfo: Object,
      group: Object,
      cardModel: Object,
      markedFields: {
        type: Array,
        default: null
      }
    },
    computed: {
      groupPluginName() {
        if (this.group?.GROUP_PLUGIN_NAME) return this.group?.GROUP_PLUGIN_NAME
        if (this.group?.GROUP_STYLE == 1) return 'MAIN'
        if (this.group?.GROUP_STYLE == 2) return 'LIST'
        return 'MAIN'
      },
      groupView() {
        return groupViews[this.groupPluginName]
      }
    },

    methods: {
      groupEditing(editing) {
        let $grp = this.$refs.groupView
        if (editing) {
          this.cardModel.addEditing($grp)
        } else {
          this.cardModel.delEditing($grp)
        }
      }     
    }
  }
</script>
<style lang="sass">
</style>
